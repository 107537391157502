import { ChainId } from '@ape.swap/sdk'
import tokens from './tokens'
import { BillArtCollection, BillsConfig, BillVersion, MigrationVestingTerms } from '../types'

// BNB Largest ID: 1023
// Polygon Largest ID: 128
// Telos Largest ID: 215
// Arbitrum Largest ID: 2004
//Migration Bonds ID: 5007

const bills: BillsConfig[] = [
  {
    index: 5000, //MIGRATION BOND BANANA 2 MONTH
    contractAddress: {
      [ChainId.BSC]: '0x302f1120aDA3f40aeD3F6410fad75e8428576a1F',
    },
    billType: 'migration',
    billVersion: BillVersion.FixedPrice,
    token: tokens.banana,
    quoteToken: tokens.banana,
    lpToken: tokens.banana,
    earnToken: tokens.abond,
    billNnftAddress: {
      [ChainId.BSC]: '0x3881ABAE74341630E4838Ca4809478DD668091fB',
    },
    inactive: false,
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    audit: 'https://paladinsec.co/projects/apeswap/',
    initTime: { [ChainId.BSC]: 1697115600 },
    initPrice: { [ChainId.BSC]: 0.05 },
    soldOut: true,
    vestingTerm: MigrationVestingTerms['60d'],
    multiplier: 1,
    billArt: {
      collection: BillArtCollection.ApeBond_Collection1,
    },
  },
  {
    index: 5001, //MIGRATION BOND BANANA 4 MONTH
    contractAddress: {
      [ChainId.BSC]: '0x872C7beE4c034CEba697de18f794e91F10062E2e',
    },
    billType: 'migration',
    billVersion: BillVersion.FixedPrice,
    token: tokens.banana,
    quoteToken: tokens.banana,
    lpToken: tokens.banana,
    earnToken: tokens.abond,
    billNnftAddress: {
      [ChainId.BSC]: '0x3881ABAE74341630E4838Ca4809478DD668091fB',
    },
    inactive: false,
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    audit: 'https://paladinsec.co/projects/apeswap/',
    initTime: { [ChainId.BSC]: 1697115600 },
    initPrice: { [ChainId.BSC]: 0.05 },
    soldOut: true,
    vestingTerm: MigrationVestingTerms['120d'],
    multiplier: 0.8943305829,
    billArt: {
      collection: BillArtCollection.ApeBond_Collection1,
    },
  },
  {
    index: 5002, //MIGRATION BOND BANANA 6 MONTH
    contractAddress: {
      [ChainId.BSC]: '0x8146d7887C2e7ce7AD0c1950E17676c632B5A71A',
    },
    billType: 'migration',
    billVersion: BillVersion.FixedPrice,
    token: tokens.banana,
    quoteToken: tokens.banana,
    lpToken: tokens.banana,
    earnToken: tokens.abond,
    billNnftAddress: {
      [ChainId.BSC]: '0x3881ABAE74341630E4838Ca4809478DD668091fB',
    },
    inactive: false,
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    audit: 'https://paladinsec.co/projects/apeswap/',
    initTime: { [ChainId.BSC]: 1697115600 },
    initPrice: { [ChainId.BSC]: 0.05 },
    soldOut: true,
    vestingTerm: MigrationVestingTerms['180d'],
    multiplier: 0.7601809955,
    billArt: {
      collection: BillArtCollection.ApeBond_Collection1,
    },
  },
  {
    index: 5003, //MIGRATION BOND BANANA 12 MONTH
    contractAddress: {
      [ChainId.BSC]: '0xFeFEDF8EB74711dF186630F4870BcA9EEDA78C85',
    },
    billType: 'migration',
    billVersion: BillVersion.FixedPrice,
    token: tokens.banana,
    quoteToken: tokens.banana,
    lpToken: tokens.banana,
    earnToken: tokens.abond,
    billNnftAddress: {
      [ChainId.BSC]: '0x3881ABAE74341630E4838Ca4809478DD668091fB',
    },
    inactive: false,
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    audit: 'https://paladinsec.co/projects/apeswap/',
    initTime: { [ChainId.BSC]: 1697115600 },
    initPrice: { [ChainId.BSC]: 0.05 },
    soldOut: true,
    vestingTerm: MigrationVestingTerms['360d'],
    multiplier: 0.6461538462,
    billArt: {
      collection: BillArtCollection.ApeBond_Collection1,
    },
  },
  {
    index: 5004, //MIGRATION BOND GNANA 2 MONTH
    contractAddress: {
      [ChainId.BSC]: '0xa2a328864f8Eca97072471cc8BF08411Da523bc9',
    },
    billType: 'migration',
    billVersion: BillVersion.FixedPrice,
    token: tokens.gnana,
    quoteToken: tokens.gnana,
    lpToken: tokens.gnana,
    earnToken: tokens.abond,
    billNnftAddress: {
      [ChainId.BSC]: '0x3881ABAE74341630E4838Ca4809478DD668091fB',
    },
    inactive: false,
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    audit: 'https://paladinsec.co/projects/apeswap/',
    initTime: { [ChainId.BSC]: 1697115600 },
    initPrice: { [ChainId.BSC]: 0.05 },
    soldOut: true,
    vestingTerm: MigrationVestingTerms['60d'],
    multiplier: 0.6998439348,
    billArt: {
      collection: BillArtCollection.ApeBond_Collection1,
    },
  },
  {
    index: 5005, //MIGRATION BOND GNANA 4 MONTH
    contractAddress: {
      [ChainId.BSC]: '0xac1CB77150Dcc3d540F08e808181CCCf0Ea29726',
    },
    billType: 'migration',
    billVersion: BillVersion.FixedPrice,
    token: tokens.gnana,
    quoteToken: tokens.gnana,
    lpToken: tokens.gnana,
    earnToken: tokens.abond,
    billNnftAddress: {
      [ChainId.BSC]: '0x3881ABAE74341630E4838Ca4809478DD668091fB',
    },
    inactive: false,
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    audit: 'https://paladinsec.co/projects/apeswap/',
    initTime: { [ChainId.BSC]: 1697115600 },
    initPrice: { [ChainId.BSC]: 0.05 },
    soldOut: true,
    vestingTerm: MigrationVestingTerms['120d'],
    multiplier: 0.6258918342,
    billArt: {
      collection: BillArtCollection.ApeBond_Collection1,
    },
  },
  {
    index: 5006, //MIGRATION BOND GNANA 6 MONTH
    contractAddress: {
      [ChainId.BSC]: '0xDCa0B400394BA6F6CBfc3Ce37B0193D4fa68eDFf',
    },
    billType: 'migration',
    billVersion: BillVersion.FixedPrice,
    token: tokens.gnana,
    quoteToken: tokens.gnana,
    lpToken: tokens.gnana,
    earnToken: tokens.abond,
    billNnftAddress: {
      [ChainId.BSC]: '0x3881ABAE74341630E4838Ca4809478DD668091fB',
    },
    inactive: false,
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    audit: 'https://paladinsec.co/projects/apeswap/',
    initTime: { [ChainId.BSC]: 1697115600 },
    initPrice: { [ChainId.BSC]: 0.05 },
    soldOut: true,
    vestingTerm: MigrationVestingTerms['180d'],
    multiplier: 0.532008059035414,
    billArt: {
      collection: BillArtCollection.ApeBond_Collection1,
    },
  },
  {
    index: 5007, //MIGRATION BOND GNANA 12 MONTH
    contractAddress: {
      [ChainId.BSC]: '0x3Eb85D568214ae04c2873880Fa715a5C8105dd17',
    },
    billType: 'migration',
    billVersion: BillVersion.FixedPrice,
    token: tokens.gnana,
    quoteToken: tokens.gnana,
    lpToken: tokens.gnana,
    earnToken: tokens.abond,
    billNnftAddress: {
      [ChainId.BSC]: '0x3881ABAE74341630E4838Ca4809478DD668091fB',
    },
    inactive: false,
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    audit: 'https://paladinsec.co/projects/apeswap/',
    initTime: { [ChainId.BSC]: 1697115600 },
    initPrice: { [ChainId.BSC]: 0.05 },
    soldOut: true,
    vestingTerm: MigrationVestingTerms['360d'],
    multiplier: 0.452206850180102,
    billArt: {
      collection: BillArtCollection.ApeBond_Collection1,
    },
  },
  {
    index: 62,
    contractAddress: {
      [ChainId.BSC]: '0xB12413a70efd97B827201a071285fBFfCAC436Bc',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.banana,
    quoteToken: tokens.wbnb,
    lpToken: tokens.bananaBnb,
    earnToken: tokens.banana,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ApeBond',
    audit: 'https://paladinsec.co/projects/apeswap/',
    initTime: { [ChainId.BSC]: 1682255105 },
    initPrice: { [ChainId.BSC]: 0.0390276 },
    soldOut: true,
    billArt: {
      collection: BillArtCollection.ApeBond_Collection1,
    },
  },
  {
    index: 69,
    contractAddress: {
      [ChainId.BSC]: '0xFebbb97b4e3C1BED9C7dCD4d2ED5f9b52FC58357',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.busd,
    quoteToken: tokens.wbnb,
    lpToken: tokens.bnbBusd,
    earnToken: tokens.banana,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    audit: 'https://paladinsec.co/projects/apeswap/',
    initTime: { [ChainId.BSC]: 1683313200 },
    initPrice: { [ChainId.BSC]: 0.0303088 },
    soldOut: true,
  },
  {
    index: 70,
    contractAddress: {
      [ChainId.BSC]: '0x7aD518872a75054c023B58619d141e3B677B5a13',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.busd,
    quoteToken: tokens.usdc,
    lpToken: tokens.usdcBusd,
    earnToken: tokens.banana,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    audit: 'https://paladinsec.co/projects/apeswap/',
    initTime: { [ChainId.BSC]: 1683313200 },
    initPrice: { [ChainId.BSC]: 0.0303088 },
    soldOut: true,
  },
  {
    index: 71,
    contractAddress: {
      [ChainId.BSC]: '0x2de795174ae129A3DE3EC65FC428427717eF1DA2',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.eth,
    quoteToken: tokens.wbnb,
    lpToken: tokens.bnbEth,
    earnToken: tokens.banana,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    audit: 'https://paladinsec.co/projects/apeswap/',
    initTime: { [ChainId.BSC]: 1683313200 },
    initPrice: { [ChainId.BSC]: 0.0303088 },
    soldOut: true,
  },
  {
    index: 72,
    contractAddress: {
      [ChainId.BSC]: '0x2Bde6DDad5D13CDD5baC871a405EB09779461E53',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.btc,
    quoteToken: tokens.wbnb,
    lpToken: tokens.bnbBtc,
    earnToken: tokens.banana,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ApeBond',
    audit: 'https://paladinsec.co/projects/apeswap/',
    initTime: { [ChainId.BSC]: 1683313200 },
    initPrice: { [ChainId.BSC]: 0.0303088 },
    soldOut: true,
  },
  {
    index: 1001,
    contractAddress: {
      [ChainId.BSC]: '0x2A5EA9cc09ad72803C83a6F4f00e907c2aA08944',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.busd,
    quoteToken: tokens.wbnb,
    lpToken: tokens.bnbBusd,
    earnToken: tokens.banana,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: true,
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ApeBond',
    audit: 'https://paladinsec.co/projects/apeswap/',
    initTime: { [ChainId.BSC]: 1699621200 },
    initPrice: { [ChainId.BSC]: 0.02559786 },
    soldOut: true,
    billArt: {
      collection: BillArtCollection.ApeBond_Collection1,
    },
  },
  {
    index: 1002,
    contractAddress: {
      [ChainId.BSC]: '0x2E0E93237b76C108D1078edA260774a466C4861a',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.busd,
    quoteToken: tokens.usdc,
    lpToken: tokens.usdcBusd,
    earnToken: tokens.banana,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: true,
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ApeBond',
    audit: 'https://paladinsec.co/projects/apeswap/',
    initTime: { [ChainId.BSC]: 1699621200 },
    initPrice: { [ChainId.BSC]: 0.02559786 },
    soldOut: true,
    billArt: {
      collection: BillArtCollection.ApeBond_Collection1,
    },
  },
  {
    index: 1003,
    contractAddress: {
      [ChainId.BSC]: '0xa2605D3EB97574e8E4d25b09942B719bFa3404b3',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.eth,
    quoteToken: tokens.wbnb,
    lpToken: tokens.bnbEth,
    earnToken: tokens.banana,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: true,
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ApeBond',
    audit: 'https://paladinsec.co/projects/apeswap/',
    initTime: { [ChainId.BSC]: 1699621200 },
    initPrice: { [ChainId.BSC]: 0.02559786 },
    soldOut: true,
    billArt: {
      collection: BillArtCollection.ApeBond_Collection1,
    },
  },
  {
    index: 1004,
    contractAddress: {
      [ChainId.BSC]: '0x0f01384C2399c145f7c8545B05F493D0C4B59873',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.btc,
    quoteToken: tokens.wbnb,
    lpToken: tokens.bnbBtc,
    earnToken: tokens.banana,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: true,
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ApeBond',
    audit: 'https://paladinsec.co/projects/apeswap/',
    initTime: { [ChainId.BSC]: 1699621200 },
    initPrice: { [ChainId.BSC]: 0.02559786 },
    soldOut: true,
    billArt: {
      collection: BillArtCollection.ApeBond_Collection1,
    },
  },
  {
    index: 1007, //ACF 60 DAYS
    contractAddress: {
      [ChainId.BSC]: '0x6D7637683eaD28F775F56506602191fdE417fF60',
    },
    billType: 'reserve',
    billVersion: BillVersion.V2,
    token: tokens.usdt,
    quoteToken: tokens.usdt,
    lpToken: tokens.usdt,
    earnToken: tokens.acf,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://ape.bond/',
    twitter: 'https://twitter.com/apebond',
    audit: 'https://paladinsec.co/projects/apeswap/',
    initTime: { [ChainId.BSC]: 1702472400 },
    initPrice: { [ChainId.BSC]: 1 },
    soldOut: true,
    billArt: {
      collection: BillArtCollection.ApeBond_Collection1,
    },
  },
  {
    index: 1010, // ACF --> ABOND
    contractAddress: {
      [ChainId.BSC]: '0xEce2b9C3704632Ce760271B0b84E3A4A698Ca962',
    },
    billType: 'reserve',
    billVersion: BillVersion.FixedPrice,
    token: tokens.acf,
    quoteToken: tokens.acf,
    lpToken: tokens.acf,
    earnToken: tokens.abond,
    billNnftAddress: {
      [ChainId.BSC]: '0x3881ABAE74341630E4838Ca4809478DD668091fB',
    },
    inactive: false,
    projectLink: 'https://ape.bond/',
    twitter: 'https://twitter.com/apebond',
    audit: 'https://paladinsec.co/projects/apeswap/',
    initTime: { [ChainId.BSC]: 1701205200 },
    initPrice: { [ChainId.BSC]: 0.00543082778284093 },
    soldOut: false,
    billArt: {
      collection: BillArtCollection.CexFundBond_Collection1,
    },
    tags: ['Cex Fund'],
  },
  {
    index: 1012,
    contractAddress: {
      [ChainId.BSC]: '0x097f6ffDC1d0C4d163d7d392a31dC7d39B710d4E',
    },
    billType: 'reserve',
    billVersion: BillVersion.V2,
    token: tokens.usdt,
    quoteToken: tokens.usdt,
    lpToken: tokens.usdt,
    earnToken: tokens.abond,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://ape.bond/',
    twitter: 'https://twitter.com/apebond',
    audit: 'https://paladinsec.co/projects/apeswap/',
    initTime: { [ChainId.BSC]: 1702998000 },
    initPrice: { [ChainId.BSC]: 0.0776096447226248 },
    soldOut: false,
    billArt: {
      collection: BillArtCollection.CexFundBond_Collection1,
    },
    tags: ['Cex Fund'],
    fullDescription:
      'ApeBond is a multi-chain bonding protocol dedicated to creating a sustainable DeFi future for projects and communities. Leveraging their innovative Bonding technology and collaborating with over 70 partners, they strive to become the leading protocol for bond solutions and sustainable liquidity.',
  },
  {
    index: 1023,
    contractAddress: {
      [ChainId.BSC]: '0x10CDAc2FD873cAceD95Ca7e532dB2766cb55D812',
    },
    billType: 'reserve',
    billVersion: BillVersion.V2,
    token: tokens.usdt,
    quoteToken: tokens.usdt,
    lpToken: tokens.usdt,
    earnToken: tokens.fav,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://home.alphaverse.com/',
    twitter: 'https://twitter.com/AlphaVersegame',
    audit: 'https://omniscia.io/reports/fav-token-implementation-64805de3e824970014254148',
    initTime: { [ChainId.BSC]: 1704891600 },
    initPrice: { [ChainId.BSC]: 0.00173967992950712 },
    soldOut: false,
    billArt: {
      collection: BillArtCollection.ApeBond_Collection1,
    },
    tags: ['Metaverse'],
    fullDescription:
      'AlphaVerse is always growing with new universes getting connected. Some are native to AlphaVerse, some are developed by their partners and third parties. Explore all of them seamlessly with one account. AlphaVerse: the central Hub where multiple universes get connected.',
  },
  {
    index: 1022,
    contractAddress: {
      [ChainId.BSC]: '0x83E22063362693Cb9C373721D037aDF6EA7762A0',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.usdt,
    quoteToken: tokens.chapz,
    lpToken: tokens.chapzUsdt_PCS,
    earnToken: tokens.chapz,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://chappyz.com/',
    twitter: 'https://twitter.com/Chappyzcom',
    initTime: { [ChainId.BSC]: 1700485200 },
    initPrice: { [ChainId.BSC]: 0.002404165611 },
    soldOut: false,
    billArt: {
      collection: BillArtCollection.ApeBond_Collection1,
    },
    fullDescription:
      'Chappyz is an AI powered plug-and-play protocol that helps build REAL community engagement & growth, whilst rewarding community members in real-time. Users can start earning rewards by simply connecting their socials and chatting. Chappyz advanced AI system scores all messages and delivers a token reward each time users chat.',
    tags: ['AI'],
  },
  {
    index: 1021,
    contractAddress: {
      [ChainId.BSC]: '0x7eDDB4A68418065de42978643A07bd32672189CD',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.monsta,
    quoteToken: tokens.wbnb,
    lpToken: tokens.monstaBnb_PCS,
    earnToken: tokens.monsta,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://cake.monster/',
    twitter: 'https://twitter.com/Monsta_BSC',
    audit: 'https://solidity.finance/audits/CakeMonster/',
    initTime: { [ChainId.BSC]: 1704996000 },
    initPrice: { [ChainId.BSC]: 0.000000031773560782 },
    soldOut: false,
    billArt: {
      collection: BillArtCollection.ApeBond_Collection1,
    },
    tags: ['Meme', 'NFT'],
    fullDescription:
      'Cake Monster ($MONSTA) is a revolutionary undertaking in sustainable Hyper-Deflationary Economics. Their team has developed a groundbreaking economic policy to combat the problems that the financial world is currently facing and wrapped it as a cute Meme on BNBChain. Users will find On-Chain Games, Play2Earn NFTs, and other rewarding features: Cake Monster is definitely THE BEAST OF BSC.',
  },
  {
    index: 1020,
    contractAddress: {
      [ChainId.BSC]: '0x591b1b8e19B492A5b5dB2c331cDD5E5E014c69F6',
    },
    billType: 'reserve',
    billVersion: BillVersion.V2,
    token: tokens.usdt,
    quoteToken: tokens.usdt,
    lpToken: tokens.usdt,
    earnToken: tokens.cakebot,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://www.cakebot.io/',
    twitter: 'https://twitter.com/CakebotTeam',
    initTime: { [ChainId.BSC]: 1704891600 },
    initPrice: { [ChainId.BSC]: 0.00173967992950712 },
    soldOut: true,
    billArt: {
      collection: BillArtCollection.ApeBond_Collection1,
    },
    tags: ['Trading Bot'],
    fullDescription:
      'Cakebot is the only all-in-one multi-ChainCurve Telegram Bot. With features such as Streamlined Trading, Precise Limit Orders, Auto Sniper, Private Transactions, Swap Aggregator and more, users can exchange without limits, and most importantly, stay ahead of the curve. Scan, Snipe and Succeed, only with Cakebot.',
  },
  {
    index: 1019,
    contractAddress: {
      [ChainId.BSC]: '0x328e243890a04cb5a763a977395F74Ea6be74D14',
    },
    billType: 'reserve',
    billVersion: BillVersion.V2,
    token: tokens.usdt,
    quoteToken: tokens.usdt,
    lpToken: tokens.usdt,
    earnToken: tokens.grape,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://www.joingrapes.com/',
    twitter: 'https://twitter.com/JoinGrapes',
    audit: 'https://sourcehat.com/audits/Grapecoin/',
    initTime: { [ChainId.BSC]: 1704891600 },
    initPrice: { [ChainId.BSC]: 0.00173967992950712 },
    soldOut: false,
    billArt: {
      collection: BillArtCollection.ApeBond_Collection1,
    },
    tags: ['NFTs'],
    fullDescription:
      'Grape NFT is the home of Grapes & $GRAPE Coin, and is backed by industry giants Animoca Brands. It’s a fun and globally appealing collection of 3,333 crafted NFTs. GRAPES has been designed to be a cross-media NFT project with gaming, animated series, merchandise and licensing. As a holder of GRAPES users will be rewarded in the commercial success of the project’s ventures both inside and outside of Web3.',
  },
  {
    index: 1018,
    contractAddress: {
      [ChainId.BSC]: '0x01bbC9b5F7d6e6C55949Bb8af118bc644d8D3f21',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.tifi,
    quoteToken: tokens.usdt,
    lpToken: tokens.tifiUsdt_PCS,
    earnToken: tokens.tifi,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://tifi.net/',
    twitter: 'https://twitter.com/TiFiToken',
    audit: 'https://skynet.certik.com/projects/tifi-token',
    initTime: { [ChainId.BSC]: 1704996000 },
    initPrice: { [ChainId.BSC]: 0.000000031773560782 },
    soldOut: false,
    billArt: {
      collection: BillArtCollection.ApeBond_Collection1,
    },
    tags: ['RWA', 'DeFi'],
    fullDescription:
      'TiFi empowers e‑Commerce with the use of Blockchain technologies. TiFi was built by a group of talented engineers in Silicon Valley and was already featured in Yahoo Finance, MSN News, and New York Weekly. TiFi Allverse, one of their main products, allows users to shop and trade real-world products with NFTs and Cryptocurrencies.',
  },
  {
    index: 1017,
    contractAddress: {
      [ChainId.BSC]: '0xd3cB03be7e367d0B6ccB42Dc87821bf67CeE8AB8',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.hntr,
    quoteToken: tokens.usdt,
    lpToken: tokens.hntrUsdt_PCS,
    earnToken: tokens.hntr,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://www.hunter-token.com/',
    twitter: 'https://twitter.com/digitalarmsnft?lang=en',
    initTime: { [ChainId.BSC]: 1704891600 },
    initPrice: { [ChainId.BSC]: 0.00173967992950712 },
    soldOut: false,
    billArt: {
      collection: BillArtCollection.ApeBond_Collection1,
    },
    tags: ['NFT Marketplace'],
    fullDescription:
      '$HNTR Token allows users to access, trade, and interact with unique, licensed NFT firearms from globally recognized brands. As part of its thriving community, users can reap lucrative benefits from their NFT staking pools with up to 200% APY rewards. Brand partners include Barrett, ZEV Technologies, ZeroTech, and more.',
  },
  {
    index: 1016,
    contractAddress: {
      [ChainId.BSC]: '0x07E48b1c531cBF32222851D0B1f77fF58b13942d',
    },
    billType: 'reserve',
    billVersion: BillVersion.V2,
    token: tokens.usdt,
    quoteToken: tokens.usdt,
    lpToken: tokens.usdt,
    earnToken: tokens.rpg,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://revolvegames.io/',
    twitter: 'https://twitter.com/RevolveGamesio',
    audit: 'https://drive.google.com/file/d/1lJdF7lEM3KQZmOW1p42Gm0eMbp7DF82C/view',
    initTime: { [ChainId.BSC]: 1704477600 },
    initPrice: { [ChainId.BSC]: 0.0257815220477653 },
    soldOut: false,
    billArt: {
      collection: BillArtCollection.ApeBond_Collection1,
    },
    tags: ['GAMING'],
    fullDescription:
      'Revolve Games is reshaping Web3 gaming - addressing many of the challenges faced by blockchain gaming economies. They directly integrate playable assets into the in-game economy, achieved by tethering their assets to the RPG token. This strategy ensures a stable and robust ecosystem, less affected by external market fluctuations.',
  },
  {
    index: 1015,
    contractAddress: {
      [ChainId.BSC]: '0xA36a59Df35B045650EeaA7d2d001675F5F822649',
    },
    billType: 'reserve',
    billVersion: BillVersion.V2,
    token: tokens.wbnb,
    quoteToken: tokens.wbnb,
    lpToken: tokens.wbnb,
    earnToken: tokens.arcas,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://www.blockapescissors.com/#home',
    twitter: 'https://twitter.com/apeblock?lang=en',
    audit: 'https://solidity.finance/audits/BlockApeScissors/',
    initTime: { [ChainId.BSC]: 1704477600 },
    initPrice: { [ChainId.BSC]: 0.103519967970037 },
    soldOut: false,
    billArt: {
      collection: BillArtCollection.ApeBond_Collection1,
    },
    tags: ['GAMING'],
    fullDescription:
      'Arcas aims to revolutionize e-sports and gaming by introducing web3 features that empower players like never before. Backed by Binance Labs Fund, they leveraged Unreal Engine 5 to create a fast paced third person competitive shooter. Their revolutionary web3 protocol blends revenue share, DAO governance, and play 2 earn functionalities.',
  },
  {
    index: 1014,
    contractAddress: {
      [ChainId.BSC]: '0x0C6f539c98Cad2Bd5dA2f5168a1C2EAA53041832',
    },
    billType: 'reserve',
    billVersion: BillVersion.V2,
    token: tokens.usdt,
    quoteToken: tokens.usdt,
    lpToken: tokens.usdt,
    earnToken: tokens.aitech,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://www.aitech.io/',
    twitter: 'https://twitter.com/AITECHio',
    audit: 'https://skynet.certik.com/projects/solidus-ai-tech',
    initTime: { [ChainId.BSC]: 1700992815 },
    initPrice: { [ChainId.BSC]: 0.0205543890538182 },
    soldOut: false,
    billArt: {
      collection: BillArtCollection.ApeBond_Collection1,
    },
    tags: ['AI'],
    fullDescription:
      'AITECH is paving the way for a more convenient and secure future by providing accessible Artificial Intelligence-as-a-Service (AIaaS), Blockchain-as-a-Service (BaaS), High-Performance Computing Power (HPC), and an artificial intelligence marketplace. All these are backed by their native token: $AITECH.',
  },
  {
    index: 1013,
    contractAddress: {
      [ChainId.BSC]: '0x3237aA0280B98C6BbE23563A5C1aBbE52bCdB378',
    },
    billType: 'reserve',
    billVersion: BillVersion.V2,
    token: tokens.usdt,
    quoteToken: tokens.usdt,
    lpToken: tokens.usdt,
    earnToken: tokens.cgpt,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://ChainGPT.org/',
    twitter: 'http://twitter.com/chain_gpt',
    audit: 'https://www.certik.com/projects/chaingpt/',
    initTime: { [ChainId.BSC]: 1704304800 },
    initPrice: { [ChainId.BSC]: 0.189643589802866 },
    soldOut: false,
    billArt: {
      collection: BillArtCollection.ApeBond_Collection1,
    },
    tags: ['AI'],
    fullDescription:
      'ChainGPT is an advanced AI infrastructure that develops AI-powered technologies for Web3, Blockchain, and Crypto space. They aim to improve the Web3 space for retail users & startups by developing AI-powered solutions designed explicitly for Web3. From LLMs to Web3 AI Tools, ChainGPT is the go-to place to boost your Web3 flow with Artificial Intelligence.',
  },
  {
    index: 1011,
    contractAddress: {
      [ChainId.BSC]: '0x00F2895AB0096984D94539387a820B193F5E7776',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.xcredi,
    quoteToken: tokens.wbnb,
    lpToken: tokens.xcrediBnb,
    earnToken: tokens.xcredi,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://www.credefi.finance/',
    twitter: 'https://twitter.com/credefi_finance/',
    audit: 'https://www.certik.com/projects/credefi?utm_source=CMC&utm_campaign=AuditByCertiKLink',
    initTime: { [ChainId.BSC]: 1702998000 },
    initPrice: { [ChainId.BSC]: 0.0776096447226248 },
    soldOut: false,
    billArt: {
      collection: BillArtCollection.ApeBond_Collection1,
    },
    tags: ['RWA', 'Lending'],
    fullDescription:
      "Credefi bridges the EU's financing gap by connecting crypto lenders with SME borrowers. Credifi provides innovative lending in order to make a real-world impact. With Credefi users can access reliable loans backed by a range of real-world assets, including real estate, future receivables, and more.",
  },
  {
    index: 1009,
    contractAddress: {
      [ChainId.BSC]: '0x87987112B74bed09D5ccbE17cAbd8EEC3e6Ff904',
    },
    billType: 'reserve',
    billVersion: BillVersion.V2,
    token: tokens.usdt,
    quoteToken: tokens.usdt,
    lpToken: tokens.usdt,
    earnToken: tokens.hgpt,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://hypergpt.ai/',
    twitter: 'https://twitter.com/hypergpt',
    audit: 'https://skynet.certik.com/projects/hypergpt?utm_source=CMC&utm_campaign=AuditByCertiKLink',
    initTime: { [ChainId.BSC]: 1701205200 },
    initPrice: { [ChainId.BSC]: 0.00543082778284093 },
    soldOut: false,
    billArt: {
      collection: BillArtCollection.ApeBond_Collection1,
    },
    fullDescription:
      'As the AI market continues to rapidly develop and hundreds of applications are released each day, there is a growing need for a marketplace platform that can bring together all these applications and community needs. HyperGPT specifically combines AI and Blockchain technologies with a new concept, creating a platform that can address these needs.',
    tags: ['AI'],
  },
  {
    index: 1008,
    contractAddress: {
      [ChainId.BSC]: '0xdBBa7C8F6Db6fA1057E58Cc0307d26e7e3F0848E',
    },
    billType: 'reserve',
    billVersion: BillVersion.V2,
    token: tokens.usdt,
    quoteToken: tokens.usdt,
    lpToken: tokens.usdt,
    earnToken: tokens.aitech,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://www.aitech.io/',
    twitter: 'https://twitter.com/AITECHio',
    audit: 'https://skynet.certik.com/projects/solidus-ai-tech',
    initTime: { [ChainId.BSC]: 1700992815 },
    initPrice: { [ChainId.BSC]: 0.02511437248 },
    soldOut: true,
    billArt: {
      collection: BillArtCollection.ApeBond_Collection1,
    },
    fullDescription:
      'AITECH is paving the way for a more convenient and secure future by providing accessible Artificial Intelligence-as-a-Service (AIaaS), Blockchain-as-a-Service (BaaS), High-Performance Computing Power (HPC), and an artificial intelligence marketplace. All these are backed by their native token: $AITECH.',
    tags: ['AI'],
  },
  {
    index: 1006,
    contractAddress: {
      [ChainId.BSC]: '0x286483b8b5E30d89b8B39C2817A9c9777e22C2C1',
    },
    billType: 'reserve',
    billVersion: BillVersion.V2,
    token: tokens.usdt,
    quoteToken: tokens.usdt,
    lpToken: tokens.usdt,
    earnToken: tokens.rpg,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://revolvegames.io/',
    twitter: 'https://twitter.com/RevolveGamesio',
    audit: 'https://drive.google.com/file/d/1lJdF7lEM3KQZmOW1p42Gm0eMbp7DF82C/view',
    initTime: { [ChainId.BSC]: 1700676000 },
    initPrice: { [ChainId.BSC]: 0.022797917366 },
    soldOut: true,
    billArt: {
      collection: BillArtCollection.ApeBond_Collection1,
    },
    fullDescription:
      'Revolve Games is reshaping Web3 gaming - addressing many of the challenges faced by blockchain gaming economies. We directly integrate playable assets into the in-game economy, achieved by tethering these assets to the RPG token. This strategy ensures a stable and robust ecosystem, less affected by external market fluctuations.',
    tags: ['Gaming'],
  },
  {
    index: 1005,
    contractAddress: {
      [ChainId.BSC]: '0x1a4ceD5Fa314Cfe70995282b5c12d751532E0923',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.usdt,
    quoteToken: tokens.chapz,
    lpToken: tokens.chapzUsdt_PCS,
    earnToken: tokens.chapz,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://chappyz.com/',
    twitter: 'https://twitter.com/Chappyzcom',
    initTime: { [ChainId.BSC]: 1700485200 },
    initPrice: { [ChainId.BSC]: 0.002404165611 },
    soldOut: true,
    billArt: {
      collection: BillArtCollection.ApeBond_Collection1,
    },
    fullDescription:
      'Chappyz is an AI powered plug-and-play protocol that helps build REAL community engagement & growth, whilst rewarding community members in real-time. Users can start earning rewards by simply connecting their socials and chatting. Chappyz advanced AI system scores all messages and delivers a token reward each time users chat.',
    tags: ['AI'],
  },
  {
    index: 1000,
    contractAddress: {
      [ChainId.BSC]: '0x114BeBd8d3178d129Ec86c47519EdB5DbdD519cA',
    },
    billType: 'reserve',
    billVersion: BillVersion.V2,
    token: tokens.usdt,
    quoteToken: tokens.usdt,
    lpToken: tokens.usdt,
    earnToken: tokens.aitech,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://www.aitech.io/',
    twitter: 'https://twitter.com/AITECHio',
    audit: 'https://skynet.certik.com/projects/solidus-ai-tech',
    initTime: { [ChainId.BSC]: 1699293600 },
    initPrice: { [ChainId.BSC]: 0.0144324243092886 },
    soldOut: true,
    billArt: {
      collection: BillArtCollection.ApeBond_Collection1,
    },
  },
  {
    index: 95,
    contractAddress: {
      [ChainId.BSC]: '0x89150e8E396Acc38AbFCAC853d6EB977AcCe13e1',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.arcas,
    quoteToken: tokens.wbnb,
    lpToken: tokens.arcasBnb_PCS,
    earnToken: tokens.arcas,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://www.blockapescissors.com/#home',
    twitter: 'https://twitter.com/apeblock?lang=en',
    audit: 'https://solidity.finance/audits/BlockApeScissors/',
    initTime: { [ChainId.BSC]: 1698411600 },
    initPrice: { [ChainId.BSC]: 0.0242848491820561 },
    soldOut: true,
    billArt: {
      collection: BillArtCollection.ApeBond_Collection1,
    },
    fullDescription:
      'Prepare for battle! Arcas is a Play-2-Earn game brought to you by Block Ape Scissors, the top Web3 gaming studio on BNBChain.',
  },
  {
    index: 94,
    contractAddress: {
      [ChainId.BSC]: '0x01a8be18770e8b2Ac2Cf42F10E4BddD6054Cbe86',
    },
    billType: 'reserve',
    billVersion: BillVersion.V2,
    token: tokens.usdt,
    quoteToken: tokens.usdt,
    lpToken: tokens.usdt,
    earnToken: tokens.hgpt,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://hypergpt.ai/',
    twitter: 'https://twitter.com/hypergpt',
    audit: 'https://skynet.certik.com/projects/hypergpt?utm_source=CMC&utm_campaign=AuditByCertiKLink',
    initTime: { [ChainId.BSC]: 1697824800 },
    initPrice: { [ChainId.BSC]: 0.00375 },
    soldOut: true,
    billArt: {
      collection: BillArtCollection.ApeBond_Collection1,
    },
    fullDescription:
      'As the AI market continues to rapidly develop and hundreds of applications are released each day, there is a growing need for a marketplace platform that can bring together all these applications and community needs. HyperGPT specifically combines AI and Blockchain technologies with a new concept, creating a platform that can address these needs.',
  },
  {
    index: 93,
    contractAddress: {
      [ChainId.BSC]: '0x93048fdB11265A68E672f47c9BdCc339D1497918',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.lgc,
    quoteToken: tokens.wbnb,
    lpToken: tokens.lgcBnb,
    earnToken: tokens.lgc,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: true,
    projectLink: 'http://livegreencoin.com/',
    twitter: 'https://twitter.com/LiveGreenCoin1',
    audit: 'https://www.certik.com/projects/livegreencoin',
    initTime: { [ChainId.BSC]: 1697479200 },
    initPrice: { [ChainId.BSC]: 0.00061926835799964 },
    soldOut: true,
  },
  {
    index: 92,
    contractAddress: {
      [ChainId.BSC]: '0xC7d087fBF15584E38a3d7d9448E4FEEe9E12c3DB',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.palm,
    quoteToken: tokens.wbnb,
    lpToken: tokens.palmBnb_PCS,
    earnToken: tokens.palm,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://palmswap.org/',
    twitter: 'https://twitter.com/Palmswaporg',
    audit: 'https://docs.palmswap.org/developers/audit',
    initTime: { [ChainId.BSC]: 1696334400 },
    initPrice: { [ChainId.BSC]: 0.00758 },
    soldOut: true,
  },
  {
    index: 91,
    contractAddress: {
      [ChainId.BSC]: '0xA2585dbF7aAcAB105542752B03fd49AB542556B8',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.cgpt,
    quoteToken: tokens.busd,
    lpToken: tokens.cgptBusd_PCS,
    earnToken: tokens.cgpt,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://ChainGPT.org/',
    twitter: 'http://twitter.com/chain_gpt',
    audit: 'https://www.certik.com/projects/chaingpt/',
    initTime: { [ChainId.BSC]: 1695225600 },
    initPrice: { [ChainId.BSC]: 0.0421361050407099 },
    soldOut: true,
    bannerURL: 'https://res.cloudinary.com/dswmrqgwy/image/upload/v1696008239/bond-highlights/gpt-banner.png',
    showcaseToken: tokens.cgpt,
    shortDescription:
      'ChainGPT 🤖 #1 ranked AI infrastructure for Blockchain, Crypto & Web3. 🔥 Unleash the power of Blockchain AI with ChainGPT. Powered by $CGPT token',
    fullDescription:
      "Unleash the power of Blockchain AI with ChainGPT. An advanced AI model explicitly designed for Blockchain Technology and Crypto-related topics. It uses the latest algorithms and high-speed computing capabilities to address challenging issues in the Blockchain and Crypto space. The ecosystem is backed and powered by the $CGPT token, which enables access to ChainGPT's premium AI tools, Governance, Launchpad, Staking, and more.",
    apeswapNote:
      'With fresh partnerships materializing almost every week and a recent win with the BNB Chain ecosystem catalyst awards, ChainGPT is rapidly gaining momentum in the crypto realm. As it emerges as a standout in the AI Blockchain sector, ChainGPT is undeniably cementing itself as a project with an enormous potential. Their Liquidity Bond is the perfect opportunity to join its enthusiastic community!',
    partnersURLS: [
      'https://res.cloudinary.com/dswmrqgwy/image/upload/v1696005003/bond-highlights/bnbchain',
      'https://res.cloudinary.com/dswmrqgwy/image/upload/v1696005160/bond-highlights/certik',
      'https://res.cloudinary.com/dswmrqgwy/image/upload/v1696005284/bond-highlights/tron',
      'https://res.cloudinary.com/dswmrqgwy/image/upload/v1696005229/bond-highlights/decrypt',
    ],
    featuredURLS: [
      'https://res.cloudinary.com/dswmrqgwy/image/upload/v1696004963/bond-highlights/bitcoincom',
      'https://res.cloudinary.com/dswmrqgwy/image/upload/v1696005124/bond-highlights/bscnews',
      'https://res.cloudinary.com/dswmrqgwy/image/upload/v1696004886/bond-highlights/beincrypto',
      'https://res.cloudinary.com/dswmrqgwy/image/upload/v1696005194/bond-highlights/cryptodaily',
    ],
  },
  {
    index: 90,
    contractAddress: {
      [ChainId.BSC]: '0xD83873BC5e762a2ff6f35C0Bb5eE687142dfDa8D',
    },
    billType: 'reserve',
    billVersion: BillVersion.V2,
    token: tokens.usdt,
    quoteToken: tokens.usdt,
    lpToken: tokens.usdt,
    earnToken: tokens.lgc,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: true,
    projectLink: 'http://livegreencoin.com/',
    twitter: 'https://twitter.com/LiveGreenCoin1',
    audit: 'https://www.certik.com/projects/livegreencoin',
    initTime: { [ChainId.BSC]: 1695052800 },
    initPrice: { [ChainId.BSC]: 0.0016842400986945 },
    soldOut: true,
  },
  {
    index: 89,
    contractAddress: {
      [ChainId.BSC]: '0xa82d7116128173F87Db5aDd4e12ebB26bd773527',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.gymnet,
    quoteToken: tokens.busd,
    lpToken: tokens.gymnetBusd_PCS,
    earnToken: tokens.gymnet,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://gymnetwork.io/',
    twitter: 'https://twitter.com/GymNet_Official',
    audit: 'https://skynet.certik.com/projects/gym-network?utm_source=CMC&utm_campaign=AuditByCertiKLink',
    initTime: { [ChainId.BSC]: 1694448000 },
    initPrice: { [ChainId.BSC]: 0.0234876697262449 },
    soldOut: true,
  },
  {
    index: 88,
    contractAddress: {
      [ChainId.BSC]: '0x80AA174d1F0BfDBfE83Cc827F6cC896C14c3AF97',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.apex,
    quoteToken: tokens.wbnb,
    lpToken: tokens.apexBnb_PCS,
    earnToken: tokens.apex,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://apexcoin.app/',
    twitter: 'https://twitter.com/apexcoin__',
    audit: 'https://github.com/AnalytixAudit/Solidity/blob/main/20230822_AnalytixAudit_ApexCoin_ApeX_Audit.pdf',
    initTime: { [ChainId.BSC]: 1694026800 },
    initPrice: { [ChainId.BSC]: 0.231136 },
    soldOut: true,
  },
  {
    index: 87,
    contractAddress: {
      [ChainId.BSC]: '0x29F7e97388985e26B178B4da3B5431477B6f533b',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.mfps,
    quoteToken: tokens.wbnb,
    lpToken: tokens.mfpsBnb_PCS,
    earnToken: tokens.mfps,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://metaplayers.gg/landing',
    twitter: 'https://twitter.com/MetaPlayersGG',
    audit: 'https://mfps.gg/assets/docs/mfps_audit.pdf',
    initTime: { [ChainId.BSC]: 1693846800 },
    initPrice: { [ChainId.BSC]: 0.00190751 },
    soldOut: true,
  },
  {
    index: 86,
    contractAddress: {
      [ChainId.BSC]: '0xdE766645C9b24e87165107714c88400FedA269A3',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.aitech,
    quoteToken: tokens.busd,
    lpToken: tokens.aitechBusd_PCS,
    earnToken: tokens.aitech,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://www.aitech.io/',
    twitter: 'https://twitter.com/AITECHio',
    audit: 'https://skynet.certik.com/projects/solidus-ai-tech',
    initTime: { [ChainId.BSC]: 1693584000 },
    initPrice: { [ChainId.BSC]: 0.0370027 },
    soldOut: true,
    bannerURL: 'https://res.cloudinary.com/dswmrqgwy/image/upload/v1694711638/aitech_xeshl8.png',
    showcaseToken: tokens.aitech,
    shortDescription:
      'Empowering the future of AI | HPC data center | IaaS platform | AIaaS | BaaS | AI Marketplace | Powered by $AITECH token | Incubated by @Chain_GPT & @decubate',
    fullDescription:
      'We are paving the way for a more convenient and secure future by providing accessible Artificial Intelligence-as-a-Service (AIAAS), Blockchain-as-a-Service (BAAS), high-performance computing power (HPC), and an artificial intelligence marketplace. All of these cutting-edge technologies are powered by our native token, AITECH.',
    apeswapNote:
      "AITech stands at the crossroads of AI and blockchain, striving to merge two pioneering technologies that are reshaping the digital landscape, with the aim of propelling them further into the mainstream. This is an exciting opportunity to support a project that's not just following the industry's pulse but aiming to set it.",
    partnersURLS: [
      'https://res.cloudinary.com/dswmrqgwy/image/upload/v1695915128/bond-highlights/bsc',
      'https://res.cloudinary.com/dswmrqgwy/image/upload/v1695915051/bond-highlights/decubate',
      'https://res.cloudinary.com/dswmrqgwy/image/upload/v1695914862/bond-highlights/gpt',
      'https://res.cloudinary.com/dswmrqgwy/image/upload/v1695914651/bond-highlights/nvidia',
    ],
    featuredURLS: [
      'https://res.cloudinary.com/dswmrqgwy/image/upload/v1695915218/bond-highlights/btc',
      'https://res.cloudinary.com/dswmrqgwy/image/upload/v1695914953/bond-highlights/forbes',
      'https://res.cloudinary.com/dswmrqgwy/image/upload/v1695914131/bond-highlights/yahoo',
      'https://res.cloudinary.com/dswmrqgwy/image/upload/v1695914539/bond-highlights/product-hunt',
    ],
  },
  {
    index: 85,
    contractAddress: {
      [ChainId.BSC]: '0x39c7A61087e881033A009CCda9c8963588b3E09D',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.dck,
    quoteToken: tokens.busd,
    lpToken: tokens.dckBusd_PCS,
    earnToken: tokens.dck,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://dexcheck.io/',
    twitter: 'https://twitter.com/DexCheck_io',
    audit: 'https://skynet.certik.com/projects/dexcheck',
    initTime: { [ChainId.BSC]: 1693497600 },
    initPrice: { [ChainId.BSC]: 0.0224148 },
    soldOut: true,
  },
  {
    index: 84,
    contractAddress: {
      [ChainId.BSC]: '0xb96D4ef47d9989e68798c3e5Bf641e8028E60c32',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.cgv,
    quoteToken: tokens.wbnb,
    lpToken: tokens.cgvBnb_PCS,
    earnToken: tokens.cgv,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://www.cogitoprotocol.com/',
    twitter: 'https://twitter.com/CogitoProtocol',
    audit: 'https://wp.hacken.io/wp-content/uploads/2023/05/Cogito_SC-Audit-Report_11_05_2023_SA-1337-1.pdf',
    initTime: { [ChainId.BSC]: 1693411200 },
    initPrice: { [ChainId.BSC]: 0.0262588 },
    soldOut: true,
  },
  {
    index: 83,
    contractAddress: {
      [ChainId.BSC]: '0x007eC17bfA484bACcbB6f6bdA20DaD1fB6A49926',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.plt,
    quoteToken: tokens.wbnb,
    lpToken: tokens.pltBnb_PCS,
    earnToken: tokens.plt,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://www.poollotto.finance/',
    twitter: 'https://twitter.com/pltfinance/',
    audit:
      'https://github.com/Quillhash/QuillAudit_Reports/blob/master/Poollotto%20Smart%20Contract%20Audit%20Report%20-%20QuillAudits.pdf',
    initTime: { [ChainId.BSC]: 1692806400 },
    initPrice: { [ChainId.BSC]: 1.83977 },
    soldOut: true,
  },
  {
    index: 99,
    contractAddress: {
      [ChainId.BSC]: '0x4Bd2Cc54D0420b4326Ff9C4185D08164dd434220',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.busd,
    quoteToken: tokens.wbnb,
    lpToken: tokens.bnbBusd,
    earnToken: tokens.banana,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: true,
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    audit: 'https://paladinsec.co/projects/apeswap/',
    initTime: { [ChainId.BSC]: 1698868800 },
    initPrice: { [ChainId.BSC]: 0.018669 },
    soldOut: true,
    billArt: {
      collection: BillArtCollection.ApeBond_Collection1,
    },
  },
  {
    index: 98,
    contractAddress: {
      [ChainId.BSC]: '0x6AB6B1332EbB75620b9AF0937251011E0B4dc5b6',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.busd,
    quoteToken: tokens.usdc,
    lpToken: tokens.usdcBusd,
    earnToken: tokens.banana,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: true,
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    audit: 'https://paladinsec.co/projects/apeswap/',
    initTime: { [ChainId.BSC]: 1698868800 },
    initPrice: { [ChainId.BSC]: 0.018669 },
    soldOut: true,
    billArt: {
      collection: BillArtCollection.ApeBond_Collection1,
    },
  },
  {
    index: 97,
    contractAddress: {
      [ChainId.BSC]: '0x80B4B7555a8F3A62e87cA2394d3EE840617F7e86',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.eth,
    quoteToken: tokens.wbnb,
    lpToken: tokens.bnbEth,
    earnToken: tokens.banana,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: true,
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    audit: 'https://paladinsec.co/projects/apeswap/',
    initTime: { [ChainId.BSC]: 1698868800 },
    initPrice: { [ChainId.BSC]: 0.018669 },
    soldOut: true,
    billArt: {
      collection: BillArtCollection.ApeBond_Collection1,
    },
  },
  {
    index: 96,
    contractAddress: {
      [ChainId.BSC]: '0xaD790aFAA1cfC35d13089c3fb6d53C411934c673',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.btc,
    quoteToken: tokens.wbnb,
    lpToken: tokens.bnbBtc,
    earnToken: tokens.banana,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: true,
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    audit: 'https://paladinsec.co/projects/apeswap/',
    initTime: { [ChainId.BSC]: 1698868800 },
    initPrice: { [ChainId.BSC]: 0.018669 },
    soldOut: true,
    billArt: {
      collection: BillArtCollection.ApeBond_Collection1,
    },
  },
  {
    index: 82,
    contractAddress: {
      [ChainId.BSC]: '0x8303dd7222b5c162C85351292b0ce26C221c4acD',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.brg,
    quoteToken: tokens.wbnb,
    lpToken: tokens.brgBnb_PCS,
    earnToken: tokens.brg,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://bridge.link/',
    twitter: 'https://twitter.com/bridge_oracle',
    audit: 'https://www.certik.com/projects/bridge-oracle?utm_source=CMC&utm_campaign=AuditByCertiKLink',
    initTime: { [ChainId.BSC]: 1692115200 },
    initPrice: { [ChainId.BSC]: 0.000387 },
    soldOut: true,
  },
  {
    index: 81,
    contractAddress: {
      [ChainId.BSC]: '0x1F13AC08BcD8E505A797A518627337eE8Ba2eEFE',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.cgpt,
    quoteToken: tokens.busd,
    lpToken: tokens.cgptBusd_PCS,
    earnToken: tokens.cgpt,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://ChainGPT.org/',
    twitter: 'http://twitter.com/chain_gpt',
    audit: 'https://www.certik.com/projects/chaingpt/',
    initTime: { [ChainId.BSC]: 1691596800 },
    initPrice: { [ChainId.BSC]: 0.06186 },
    soldOut: true,
  },
  {
    index: 80,
    contractAddress: {
      [ChainId.BSC]: '0xDB728D1503079B5313dD80257580fc5dC476AFd3',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.furfi,
    quoteToken: tokens.wbnb,
    lpToken: tokens.furfiBnb,
    earnToken: tokens.furfi,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: true,
    projectLink: 'https://furiofi.com/',
    twitter: 'https://twitter.com/furiocrypto',
    audit: 'https://solidity.finance/audits/Furio/',
    initTime: { [ChainId.BSC]: 1691427600 },
    initPrice: { [ChainId.BSC]: 4.85872 },
    soldOut: true,
  },
  {
    index: 79,
    contractAddress: {
      [ChainId.BSC]: '0xbD1dcE65a58a533259c134a3476a97A627De5F1e',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.gq,
    quoteToken: tokens.busd,
    lpToken: tokens.gqBusd,
    earnToken: tokens.gq,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://outerringmmo.com/',
    twitter: 'https://twitter.com/OuterRingMMO',
    initTime: { [ChainId.BSC]: 1690208985 },
    initPrice: { [ChainId.BSC]: 0.00301 },
    soldOut: true,
  },
  {
    index: 78,
    contractAddress: {
      [ChainId.BSC]: '0xb48fDf78CAcd6d2fC495beFA51395CE9295B05E8',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.froyo,
    quoteToken: tokens.wbnb,
    lpToken: tokens.froyoBnb,
    earnToken: tokens.froyo,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: true,
    projectLink: 'https://froyo.games/',
    twitter: 'https://twitter.com/realfroyogames',
    audit:
      'https://github.com/froyogames/public-smart-contracts/blob/main/Smart%20contract%20security%20audit%20report%20-%20Froyo.pdf',
    initTime: { [ChainId.BSC]: 1689112800 },
    initPrice: { [ChainId.BSC]: 0.00106081 },
    soldOut: true,
  },
  {
    index: 77,
    contractAddress: {
      [ChainId.BSC]: '0x3aCfbf26c5906924738dCd6Ec417346f28f2bEbF',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.cgpt,
    quoteToken: tokens.busd,
    lpToken: tokens.cgptBusd,
    earnToken: tokens.cgpt,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://ChainGPT.org/',
    twitter: 'http://twitter.com/chain_gpt',
    audit: 'https://www.certik.com/projects/chaingpt/',
    initTime: { [ChainId.BSC]: 1688400000 },
    initPrice: { [ChainId.BSC]: 0.08075 },
    soldOut: true,
  },
  {
    index: 76,
    contractAddress: {
      [ChainId.BSC]: '0x265F0332D04384AB8123488A6b889C5B470B07F8',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.nfai,
    quoteToken: tokens.wbnb,
    lpToken: tokens.nfaiBnb,
    earnToken: tokens.nfbi,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://legacy.apeswap.finance/nft',
    twitter: 'https://twitter.com/ApeSwapNFT',
    audit: 'https://paladinsec.co/projects/apeswap/',
    initTime: { [ChainId.BSC]: 1687816800 },
    initPrice: { [ChainId.BSC]: 0.0635458 },
    soldOut: true,
    showcaseToken: tokens.nfai,
  },
  {
    index: 75,
    contractAddress: {
      [ChainId.BSC]: '0x6543C13e6dFf99E340147a69bBF38A7C5cfc07Fa',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.lgc,
    quoteToken: tokens.wbnb,
    lpToken: tokens.lgcBnb,
    earnToken: tokens.lgc,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: true,
    projectLink: 'http://livegreencoin.com/',
    twitter: 'https://twitter.com/LiveGreenCoin1',
    audit: 'https://www.certik.com/projects/livegreencoin',
    initTime: { [ChainId.BSC]: 1687447800 },
    initPrice: { [ChainId.BSC]: 0.0056 },
    soldOut: true,
  },
  {
    index: 74,
    contractAddress: {
      [ChainId.BSC]: '0x26a956eae3aF0dBd24c0c0279614336fBBBef968',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.noot,
    quoteToken: tokens.wbnb,
    lpToken: tokens.nootBnb,
    earnToken: tokens.noot,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://www.noot.fun/',
    twitter: 'https://twitter.com/nootcoinbnb',
    audit: 'https://techright.io/audit/nootcoin/',
    initTime: { [ChainId.BSC]: 1684771200 },
    initPrice: { [ChainId.BSC]: 0.00000000872237 },
    soldOut: true,
  },
  {
    index: 67,
    contractAddress: {
      [ChainId.BSC]: '0x14FE608b4BE893ab1d7f36Be6B27C4971dcc364C',
    },
    billType: 'reserve',
    billVersion: BillVersion.V2,
    token: tokens.usdt,
    quoteToken: tokens.usdt,
    lpToken: tokens.usdt,
    earnToken: tokens.kkc,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: true,
    projectLink: 'https://www.knoknok.io/',
    twitter: 'https://twitter.com/knoknok_elf',
    initTime: { [ChainId.BSC]: 1682690400 },
    initPrice: { [ChainId.BSC]: 0.015 },
    soldOut: true,
  },
  {
    index: 66,
    contractAddress: {
      [ChainId.BSC]: '0x48288b61D8dD929aeaCDDc11C8c2e150e29c02eA',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.kkc,
    quoteToken: tokens.usdt,
    lpToken: tokens.kkcUsdt,
    earnToken: tokens.kkc,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: true,
    projectLink: 'https://www.knoknok.io/',
    twitter: 'https://twitter.com/knoknok_elf',
    initTime: { [ChainId.BSC]: 1682690400 },
    initPrice: { [ChainId.BSC]: 0.015 },
    soldOut: true,
  },
  {
    index: 73,
    contractAddress: {
      [ChainId.BSC]: '0xD26C446Bc7C130edA91B21f6883068499dd2c0B6',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.rock,
    quoteToken: tokens.wbnb,
    lpToken: tokens.rockBnb,
    earnToken: tokens.rock,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://www.bedrockswap.finance/#',
    twitter: 'https://twitter.com/Bedrockswap',
    audit: 'https://skynet.certik.com/projects/bedrock?utm_source=CMC&utm_campaign=AuditByCertiKLink',
    initTime: { [ChainId.BSC]: 1683313200 },
    initPrice: { [ChainId.BSC]: 0.00292599 },
    soldOut: true,
  },
  {
    index: 68,
    contractAddress: {
      [ChainId.BSC]: '0x07F09E925017F2423d05c2fA88d23CCC93aC296C',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.avg,
    quoteToken: tokens.wbnb,
    lpToken: tokens.avgBnb,
    earnToken: tokens.avg,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://avocadodao.io/',
    twitter: 'https://twitter.com/Avocadoguild?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor',
    audit: 'https://skynet.certik.com/projects/avocado-dao',
    initTime: { [ChainId.BSC]: 1682950826 },
    initPrice: { [ChainId.BSC]: 0.0448743 },
    soldOut: true,
  },
  {
    index: 65,
    contractAddress: {
      [ChainId.BSC]: '0xB883CF791A81e4ac248360b9E5cFF4cb9b5d45f6',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.cgg,
    quoteToken: tokens.usdc,
    lpToken: tokens.cggUsdc,
    earnToken: tokens.usdc,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://chainguardians.io/',
    twitter: 'https://twitter.com/chain_guardians',
    audit: 'https://www.certik.com/projects/chainguardians?utm_source=CMC&utm_campaign=AuditByCertiKLink',
    initTime: { [ChainId.BSC]: 1682590408 },
    initPrice: { [ChainId.BSC]: 0.0846315 },
    soldOut: true,
    showcaseToken: tokens.cgg,
  },
  {
    index: 64,
    contractAddress: {
      [ChainId.BSC]: '0x7a86f9938a8e68e3a9fb62eD84031F8c7C052aDb',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.froyo,
    quoteToken: tokens.wbnb,
    lpToken: tokens.froyoBnb,
    earnToken: tokens.froyo,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: true,
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/realfroyogames',
    audit:
      'https://github.com/froyogames/public-smart-contracts/blob/main/Smart%20contract%20security%20audit%20report%20-%20Froyo.pdf',
    initTime: { [ChainId.BSC]: 1682255105 },
    initPrice: { [ChainId.BSC]: 0.003065 },
    soldOut: true,
  },
  {
    index: 61,
    contractAddress: {
      [ChainId.BSC]: '0x966d6F3e6a8b59296F1e25a08b45154318Aea3cb',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.ubxs,
    quoteToken: tokens.wbnb,
    lpToken: tokens.ubxsBnb,
    earnToken: tokens.ubxs,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://bixos.io/',
    twitter: 'https://twitter.com/bixosinc',
    audit: 'https://github.com/solidproof/projects/blob/main/Bixos/SmartContract_Audit_Solidproof_Bixos.pdf',
    initTime: { [ChainId.BSC]: 1681407727 },
    initPrice: { [ChainId.BSC]: 0.0650251 },
    soldOut: true,
  },
  {
    index: 60,
    contractAddress: {
      [ChainId.BSC]: '0xc3111525f0CeEB7565939c8Da18D9cDD67C7B219',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.monsta,
    quoteToken: tokens.wbnb,
    lpToken: tokens.monstaBnb,
    earnToken: tokens.monsta,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://cake.monster/',
    twitter: 'https://twitter.com/thecakemnstr',
    audit: 'https://solidity.finance/audits/CakeMonster/',
    initTime: { [ChainId.BSC]: 1680097097 },
    initPrice: { [ChainId.BSC]: 0.00100443 },
    soldOut: true,
  },
  {
    index: 59,
    contractAddress: {
      [ChainId.BSC]: '0x83a9d9c278f2ccE356667EbdAD20c9B4BA68d5aE',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.credi,
    quoteToken: tokens.wbnb,
    lpToken: tokens.crediBnb,
    earnToken: tokens.credi,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://www.credefi.finance/',
    twitter: 'https://twitter.com/credefi_finance/',
    audit: 'https://www.certik.com/projects/credefi?utm_source=CMC&utm_campaign=AuditByCertiKLink',
    initTime: { [ChainId.BSC]: 1678922163 },
    initPrice: { [ChainId.BSC]: 0.00251252 },
    soldOut: true,
  },
  {
    index: 58,
    contractAddress: {
      [ChainId.BSC]: '0x9b86b2baE5B9C7eD73264Cbfdf1b819496Fdc091',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.cgg,
    quoteToken: tokens.usdc,
    lpToken: tokens.cggUsdc,
    earnToken: tokens.usdc,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://chainguardians.io/',
    twitter: 'https://twitter.com/chain_guardians',
    audit: 'https://www.certik.com/projects/chainguardians?utm_source=CMC&utm_campaign=AuditByCertiKLink',
    initTime: { [ChainId.BSC]: 1678269604 },
    initPrice: { [ChainId.BSC]: 0.110177 },
    soldOut: true,
    showcaseToken: tokens.cgg,
  },
  {
    index: 57,
    contractAddress: {
      [ChainId.BSC]: '0x31CA3536a7B3560e56a0BEA226135Cf809D2E237',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.lgc,
    quoteToken: tokens.wbnb,
    lpToken: tokens.lgcBnb,
    earnToken: tokens.lgc,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: true,
    projectLink: 'http://livegreencoin.com/',
    twitter: 'https://twitter.com/LiveGreenCoin1',
    audit: 'https://www.certik.com/projects/livegreencoin',
    initTime: { [ChainId.BSC]: 1678053061 },
    initPrice: { [ChainId.BSC]: 0.00927457 },
    soldOut: true,
  },
  {
    index: 56,
    contractAddress: {
      [ChainId.BSC]: '0xCdCC22C01e1660713B45fE83d7C23787792931B5',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.oath,
    quoteToken: tokens.usdc,
    lpToken: tokens.oathUsdc,
    earnToken: tokens.oath,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://www.reaper.farm/',
    twitter: 'https://twitter.com/Reaper_Farm',
    audit: 'https://solidity.finance/audits/ReaperFarm/',
    initTime: { [ChainId.BSC]: 1677676077 },
    initPrice: { [ChainId.BSC]: 0.2253 },
    soldOut: true,
  },
  {
    index: 55,
    contractAddress: {
      [ChainId.BSC]: '0x4E96152511D29c99c47A24B07a3b98738e0fb9BA',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.toko,
    quoteToken: tokens.wbnb,
    lpToken: tokens.tokoBnb,
    earnToken: tokens.toko,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://tokoin.io/',
    twitter: 'https://twitter.com/tokoinglobal',
    audit: 'https://github.com/peckshield/publications/blob/master/audit_reports/PeckShield-Audit-Report-T-Fi-v1.0.pdf',
    initTime: { [ChainId.BSC]: 1677184302 },
    initPrice: { [ChainId.BSC]: 0.00434657 },
    soldOut: true,
  },
  {
    index: 54,
    contractAddress: {
      [ChainId.BSC]: '0x60219D7ABd2381230BC250548109b464f44e44dC',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.cvl,
    quoteToken: tokens.usdt,
    lpToken: tokens.cvlUsdt,
    earnToken: tokens.cvl,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://cvl.network',
    twitter: 'https://twitter.com/cvl_network',
    audit: 'https://github.com/xdao-app/xdao-contracts/blob/master/audits/hacken/hacken.pdf',
    initTime: { [ChainId.BSC]: 1677080938 },
    initPrice: { [ChainId.BSC]: 0.00864348 },
    soldOut: true,
  },
  {
    index: 53,
    contractAddress: {
      [ChainId.BSC]: '0xF3DADCFa7114652Ca750e0C653A00DeCB63B4d0F',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.trivia,
    quoteToken: tokens.wbnb,
    lpToken: tokens.triviaBnb,
    earnToken: tokens.trivia,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://trivians.io/',
    twitter: 'https://twitter.com/PlayTrivians',
    audit: 'https://hacken.io/wp-content/uploads/2022/06/Trivians_29062022_SCAudit_Report_2.pdf',
    initTime: { [ChainId.BSC]: 1676467044 },
    initPrice: { [ChainId.BSC]: 0.00342517 },
    soldOut: true,
  },
  {
    index: 52,
    contractAddress: {
      [ChainId.BSC]: '0x6ed6Ccf1f55f3C42438265d1c1C58B9e6756fb1a',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.xtal,
    quoteToken: tokens.busd,
    lpToken: tokens.xtalBusd,
    earnToken: tokens.xtal,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: false,
    projectLink: 'https://talesofxtalnia.com/',
    twitter: 'https://twitter.com/xtalnia',
    audit: 'https://github.com/HashEx/public_audits/blob/master/XTAL%20Token/XTAL%20Token.pdf',
    initTime: { [ChainId.BSC]: 1676322242 },
    initPrice: { [ChainId.BSC]: 0.438389 },
    soldOut: true,
  },
  {
    index: 46,
    contractAddress: {
      [ChainId.BSC]: '0x12c42b96ff1248471bbc9da06d7fee828e6f7db2',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.wcflt,
    quoteToken: tokens.wbnb,
    lpToken: tokens.wcfltBnb,
    earnToken: tokens.wcflt,
    billNnftAddress: {
      [ChainId.BSC]: '0x7FD203888661d1F0AE625eD599909f8F97698670',
    },
    inactive: false,
    projectLink: 'https://www.coinflect.com/',
    twitter: 'https://twitter.com/coinflect?lang=en',
    audit:
      'https://github.com/coinflect/wrapped-coinflect-audit/blob/main/Wrapped%20Coinflect%20Smart%20Contract%20Security%20Audit.pdf',
    initTime: { [ChainId.BSC]: 1675861237 },
    initPrice: { [ChainId.BSC]: 0.00004566 },
    soldOut: true,
  },
  {
    index: 45,
    contractAddress: {
      [ChainId.BSC]: '0x44c2211976f796d439e82036006d9559c1b1fbdb',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.avan,
    quoteToken: tokens.wbnb,
    lpToken: tokens.avanBnb,
    earnToken: tokens.avan,
    billNnftAddress: {
      [ChainId.BSC]: '0x7FD203888661d1F0AE625eD599909f8F97698670',
    },
    inactive: false,
    projectLink: 'https://avanatoken.com/',
    twitter: 'https://twitter.com/AvanaProject',
    audit: 'https://hashex.org/audits/avana-token/',
    initTime: { [ChainId.BSC]: 1675687946 },
    initPrice: { [ChainId.BSC]: 0.029782 },
    soldOut: true,
  },
  {
    index: 43,
    contractAddress: {
      [ChainId.BSC]: '0x279c47aE7846962037077338505c657ad65D0DeE',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.liq,
    quoteToken: tokens.busd,
    lpToken: tokens.liqBusd,
    earnToken: tokens.liq,
    billNnftAddress: {
      [ChainId.BSC]: '0x7FD203888661d1F0AE625eD599909f8F97698670',
    },
    inactive: false,
    projectLink: 'https://liquidus.finance/',
    twitter: 'https://twitter.com/LiquidusFinance',
    audit: 'https://liquidus.finance/LiqTechRateAudit.pdf',
    initTime: { [ChainId.BSC]: 1674588602 },
    initPrice: { [ChainId.BSC]: 0.367212 },
    soldOut: true,
  },
  {
    index: 44,
    contractAddress: {
      [ChainId.BSC]: '0xaf7F491e77f4979faadbE24866D4471De23bd0a0',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.monsta,
    quoteToken: tokens.wbnb,
    lpToken: tokens.monstaBnb,
    earnToken: tokens.monsta,
    billNnftAddress: {
      [ChainId.BSC]: '0x7FD203888661d1F0AE625eD599909f8F97698670',
    },
    inactive: false,
    projectLink: 'https://cake.monster/',
    twitter: 'https://twitter.com/thecakemnstr',
    audit: 'https://solidity.finance/audits/CakeMonster/',
    initTime: { [ChainId.BSC]: 1674763153 },
    initPrice: { [ChainId.BSC]: 0.000919952 },
    soldOut: true,
  },
  {
    index: 37,
    contractAddress: {
      [ChainId.BSC]: '0x2e9bdb018850747dc557d13046b8259ab24c674a',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.radar,
    quoteToken: tokens.wbnb,
    lpToken: tokens.radarBnb,
    earnToken: tokens.radar,
    billNnftAddress: {
      [ChainId.BSC]: '0x7FD203888661d1F0AE625eD599909f8F97698670',
    },
    inactive: false,
    projectLink: 'https://dappradar.com/',
    twitter: 'https://twitter.com/DappRadar/',
    audit: 'https://dappradar.com/documents/RADAR-Audits-v1.pdf',
    initTime: { [ChainId.BSC]: 1668620528 },
    initPrice: { [ChainId.BSC]: 0.005311928477767259 },
    soldOut: true,
  },
  {
    index: 48,
    contractAddress: {
      [ChainId.BSC]: '0xDfE0C1Ee6E3383E2E50f95541881040d278720C9',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.busd,
    quoteToken: tokens.wbnb,
    lpToken: tokens.bnbBusd,
    earnToken: tokens.banana,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: true,
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    initTime: { [ChainId.BSC]: 1675904338 },
    initPrice: { [ChainId.BSC]: 0.073805 },
    soldOut: true,
  },
  {
    index: 49,
    contractAddress: {
      [ChainId.BSC]: '0x9632D29b4786699C4262F59526D0DA0C9E7C7a72',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.busd,
    quoteToken: tokens.usdc,
    lpToken: tokens.usdcBusd,
    earnToken: tokens.banana,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: true,
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    initTime: { [ChainId.BSC]: 1675904338 },
    initPrice: { [ChainId.BSC]: 0.073805 },
    soldOut: true,
  },
  {
    index: 50,
    contractAddress: {
      [ChainId.BSC]: '0x1Ca83Ba8e7927e65764Ff07A61BCe8D12147CBD9',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.eth,
    quoteToken: tokens.wbnb,
    lpToken: tokens.bnbEth,
    earnToken: tokens.banana,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: true,
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    initTime: { [ChainId.BSC]: 1675904338 },
    initPrice: { [ChainId.BSC]: 0.073805 },
    soldOut: true,
  },
  {
    index: 51,
    contractAddress: {
      [ChainId.BSC]: '0xAB60c836793e04DDA3168fCd4e36AF52Bf6e65Cf',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.btc,
    quoteToken: tokens.wbnb,
    lpToken: tokens.bnbBtc,
    earnToken: tokens.banana,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: true,
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    initTime: { [ChainId.BSC]: 1675904338 },
    initPrice: { [ChainId.BSC]: 0.073805 },
    soldOut: true,
  },
  {
    index: 47,
    contractAddress: {
      [ChainId.BSC]: '0xc10BD711C939f972aB7523dc1E948E5DB53B5759',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.banana,
    quoteToken: tokens.wbnb,
    lpToken: tokens.bananaBnb,
    earnToken: tokens.banana,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    inactive: true,
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    initTime: { [ChainId.BSC]: 1675904338 },
    initPrice: { [ChainId.BSC]: 0.073805 },
    soldOut: true,
  },
  {
    index: 38,
    contractAddress: {
      [ChainId.BSC]: '0x5aabc088b42c197c8da69af5471aa06c3852912b',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.ihc,
    quoteToken: tokens.wbnb,
    lpToken: tokens.ihcBnb,
    earnToken: tokens.ihc,
    billNnftAddress: {
      [ChainId.BSC]: '0x7FD203888661d1F0AE625eD599909f8F97698670',
    },
    inactive: false,
    projectLink: 'https://www.ihcoin.io/',
    twitter: 'https://twitter.com/IHCoinofficial',
    audit: 'https://www.certik.com/projects/ihc#audit',
    initTime: { [ChainId.BSC]: 1669045328 },
    initPrice: { [ChainId.BSC]: 0.0000773918161395094 },
    soldOut: true,
  },
  {
    index: 39,
    contractAddress: {
      [ChainId.BSC]: '0xfe69f6f456fdc5535e7a7a03bb78f739400bc8e0',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.liq,
    quoteToken: tokens.busd,
    lpToken: tokens.liqBusd,
    earnToken: tokens.liq,
    billNnftAddress: {
      [ChainId.BSC]: '0x7FD203888661d1F0AE625eD599909f8F97698670',
    },
    inactive: false,
    projectLink: 'https://liquidus.finance/',
    twitter: 'https://twitter.com/LiquidusFinance',
    initTime: { [ChainId.BSC]: 1669794128 },
    initPrice: { [ChainId.BSC]: 0.3590438711755946 },
    soldOut: true,
  },
  {
    index: 40,
    contractAddress: {
      [ChainId.BSC]: '0x74dab17e30b974a3590796a8ba2223a50da6a32c',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.pstn,
    quoteToken: tokens.busd,
    lpToken: tokens.pstnBusd,
    earnToken: tokens.pstn,
    billNnftAddress: {
      [ChainId.BSC]: '0x7FD203888661d1F0AE625eD599909f8F97698670',
    },
    inactive: false,
    projectLink: 'https://piston-token.com/',
    twitter: 'https://mobile.twitter.com/pistontoken',
    initTime: { [ChainId.BSC]: 1669905728 },
    initPrice: { [ChainId.BSC]: 2.000004210715095 },
    soldOut: true,
  },
  {
    index: 41,
    contractAddress: {
      [ChainId.BSC]: '0xEd242A13d25b5B2270E90058C9a1A756811cdb93',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.gq,
    quoteToken: tokens.busd,
    lpToken: tokens.gqBusd,
    earnToken: tokens.gq,
    billNnftAddress: {
      [ChainId.BSC]: '0x7FD203888661d1F0AE625eD599909f8F97698670',
    },
    inactive: false,
    projectLink: 'https://outerringmmo.com/',
    twitter: 'https://twitter.com/OuterRingMMO',
    initTime: { [ChainId.BSC]: 1670432974 },
    initPrice: { [ChainId.BSC]: 0.001714637510619517 },
    soldOut: true,
  },
  {
    index: 42,
    contractAddress: {
      [ChainId.BSC]: '0xb95bd2dc93562fd9076c0573615de42b413db256',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.leap,
    quoteToken: tokens.wbnb,
    lpToken: tokens.leapBnb,
    earnToken: tokens.leap,
    billNnftAddress: {
      [ChainId.BSC]: '0x7FD203888661d1F0AE625eD599909f8F97698670',
    },
    inactive: false,
    projectLink: 'https://www.leaptoken.io/',
    twitter: 'https://twitter.com/Play_Leap',
    audit: 'https://github.com/HashEx/public_audits/blob/master/PlayLeap/PlayLeap.pdf',
    initTime: { [ChainId.BSC]: 1673368322 },
    initPrice: { [ChainId.BSC]: 0.165579 },
    soldOut: true,
  },
  {
    index: 30,
    contractAddress: {
      [ChainId.BSC]: '0xced994206cd4c364d7bda2b66b48fcaef9b6bb5b',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.nfty,
    quoteToken: tokens.busd,
    lpToken: tokens.nftyBusd,
    earnToken: tokens.nfty,
    billNnftAddress: {
      [ChainId.BSC]: '0x7fd203888661d1f0ae625ed599909f8f97698670',
    },
    inactive: false,
    projectLink: 'https://nftynetwork.io/',
    twitter: 'https://twitter.com/NFTYFinance',
    initTime: { [ChainId.BSC]: 1667839328 },
    initPrice: { [ChainId.BSC]: 0.005420113056475245 },
    soldOut: true,
  },
  {
    index: 29,
    contractAddress: {
      [ChainId.BSC]: '0x7aa1939b8284a2defdcb5e983de55da2ad61f607',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.threeair,
    quoteToken: tokens.wbnb,
    lpToken: tokens.threeairBnb,
    earnToken: tokens.threeair,
    billNnftAddress: {
      [ChainId.BSC]: '0x7fd203888661d1f0ae625ed599909f8f97698670',
    },
    inactive: false,
    projectLink: 'https://3air.io/',
    twitter: 'https://twitter.com/3aircrypto',
    audit: 'https://github.com/JorgeRodriguezsec/CTDsec/blob/main/Audits/Cybersecurity_Audit_CTDSEC_3air.pdf',
    initTime: { [ChainId.BSC]: 1667512328 },
    initPrice: { [ChainId.BSC]: 0.04540120370089731 },
    soldOut: true,
  },
  {
    index: 27,
    contractAddress: {
      [ChainId.BSC]: '0x626b5c18a2b702a189ea022df897a4f0f3566785',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.coc,
    quoteToken: tokens.wbnb,
    lpToken: tokens.cocBnb,
    earnToken: tokens.coc,
    billNnftAddress: {
      [ChainId.BSC]: '0x7fd203888661d1f0ae625ed599909f8f97698670',
    },
    inactive: false,
    projectLink: 'https://www.coinofchampions.com/',
    twitter: 'https://twitter.com/coinofchampions',
    initTime: { [ChainId.BSC]: 1667352248 },
    initPrice: { [ChainId.BSC]: 1.2566456303843458e-7 },
    soldOut: true,
  },
  {
    index: 22,
    contractAddress: {
      [ChainId.BSC]: '0x7b1dfe9ca71ffeac5ed4d1beb592771e3672a2de',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.chrp,
    quoteToken: tokens.wbnb,
    lpToken: tokens.chrpBnb,
    earnToken: tokens.chrp,
    billNnftAddress: {
      [ChainId.BSC]: '0x7fd203888661d1f0ae625ed599909f8f97698670',
    },
    inactive: false,
    projectLink: 'https://chirpley.ai',
    twitter: 'https://twitter.com/chirpley',
    audit: 'https://stack.chirpley.nl/s/241c8gWBtVHnuZO1',
    initTime: { [ChainId.BSC]: 1664883258 },
    initPrice: { [ChainId.BSC]: 0.006475439494480084 },
    soldOut: true,
  },
  {
    index: 20,
    contractAddress: {
      [ChainId.BSC]: '0xb646c4a51365598433ba4765b890ad3075e4e21e',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.gmr,
    quoteToken: tokens.wbnb,
    lpToken: tokens.gmrBnb,
    earnToken: tokens.gmr,
    billNnftAddress: {
      [ChainId.BSC]: '0x7fd203888661d1f0ae625ed599909f8f97698670',
    },
    inactive: false,
    projectLink: 'https://gmr.center/',
    twitter: 'https://twitter.com/GMRCenter',
    audit: 'https://www.certik.com/projects/gamer',
    initTime: { [ChainId.BSC]: 1662032058 },
    initPrice: { [ChainId.BSC]: 0.019076325851224644 },
    soldOut: true,
  },
  {
    index: 31,
    contractAddress: {
      [ChainId.BSC]: '0x63571f457f246124c6968579E8797B91613dED20',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.xcur,
    quoteToken: tokens.busd,
    lpToken: tokens.xcurBusd,
    earnToken: tokens.xcur,
    billNnftAddress: {
      [ChainId.BSC]: '0x7fd203888661d1f0ae625ed599909f8f97698670',
    },
    inactive: false,
    projectLink: 'https://web.curate.style/',
    twitter: 'https://twitter.com/curateproject',
    initTime: { [ChainId.BSC]: 1662032058 },
    initPrice: { [ChainId.BSC]: 0.019076325851224644 },
    soldOut: true,
  },
  {
    index: 28,
    contractAddress: {
      [ChainId.BSC]: '0x7fdb62f681d7aad8c08a1d6af350e2fb7f265336',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.gq,
    quoteToken: tokens.busd,
    lpToken: tokens.gqBusd,
    earnToken: tokens.gq,
    billNnftAddress: {
      [ChainId.BSC]: '0x7fd203888661d1f0ae625ed599909f8f97698670',
    },
    inactive: false,
    projectLink: 'https://outerringmmo.com/',
    twitter: 'https://twitter.com/OuterRingMMO',
    initTime: { [ChainId.BSC]: 1667483648 },
    initPrice: { [ChainId.BSC]: 0.002164726526133125 },
    soldOut: true,
  },
  {
    index: 26,
    contractAddress: {
      [ChainId.BSC]: '0x07968648273e29f5e2fe07aabf5fbc3b40892592',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.pstn,
    quoteToken: tokens.busd,
    lpToken: tokens.pstnBusd,
    earnToken: tokens.pstn,
    billNnftAddress: {
      [ChainId.BSC]: '0x7fd203888661d1f0ae625ed599909f8f97698670',
    },
    inactive: false,
    projectLink: 'https://piston-token.com/',
    twitter: 'https://twitter.com/MyDefi_team',
    soldOut: true,
  },
  {
    index: 25,
    contractAddress: {
      [ChainId.BSC]: '0xff674209beeb493f9e8c44898848c6cfd710e98f',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.xwin,
    quoteToken: tokens.wbnb,
    lpToken: tokens.xwinBnb,
    earnToken: tokens.xwin,
    billNnftAddress: {
      [ChainId.BSC]: '0x7fd203888661d1f0ae625ed599909f8f97698670',
    },
    inactive: false,
    projectLink: 'https://xwin.finance/',
    twitter: 'https://twitter.com/xwinfinance',
    initTime: { [ChainId.BSC]: 1666784058 },
    initPrice: { [ChainId.BSC]: 0.550605316141175 },
    soldOut: true,
  },
  {
    index: 24,
    contractAddress: {
      [ChainId.BSC]: '0x2f8042167b0549ef56e55b9c1ace661b10ad93ea',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.froyo,
    quoteToken: tokens.wbnb,
    lpToken: tokens.froyoBnb,
    earnToken: tokens.froyo,
    billNnftAddress: {
      [ChainId.BSC]: '0x7fd203888661d1f0ae625ed599909f8f97698670',
    },
    inactive: true,
    projectLink: 'https://froyo.games/',
    twitter: 'https://twitter.com/realfroyogames',
    initTime: { [ChainId.BSC]: 1666092858 },
    initPrice: { [ChainId.BSC]: 0.010365834030975409 },
    soldOut: true,
  },
  {
    index: 23,
    contractAddress: {
      [ChainId.BSC]: '0xc117e57f4fa2fc1c2759228e0f85bc6d1c89be36',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.froyo,
    quoteToken: tokens.wbnb,
    lpToken: tokens.froyoBnb,
    earnToken: tokens.froyo,
    billNnftAddress: {
      [ChainId.BSC]: '0x7fd203888661d1f0ae625ed599909f8f97698670',
    },
    inactive: true,
    projectLink: 'https://froyo.games/',
    twitter: 'https://twitter.com/realfroyogames',
    soldOut: true,
  },
  {
    index: 21,
    contractAddress: {
      [ChainId.BSC]: '0x7e7842f762b27a024f7c3b95495f1d132c554d6b',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.blid,
    quoteToken: tokens.usdt,
    lpToken: tokens.blidUsdt,
    earnToken: tokens.blid,
    billNnftAddress: {
      [ChainId.BSC]: '0x7fd203888661d1f0ae625ed599909f8f97698670',
    },
    inactive: false,
    projectLink: 'https://bolide.fi/',
    twitter: 'https://twitter.com/Bolide_fi',
    initTime: { [ChainId.BSC]: 1664192058 },
    initPrice: { [ChainId.BSC]: 0.024065964757317017 },
    soldOut: true,
  },
  {
    index: 19,
    contractAddress: {
      [ChainId.BSC]: '0x99eea57cdc8983992a3c1505dbf7181467daa22f',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.hec,
    quoteToken: tokens.wbnb,
    lpToken: tokens.hecBnb,
    earnToken: tokens.hec,
    billNnftAddress: {
      [ChainId.BSC]: '0x7fd203888661d1f0ae625ed599909f8f97698670',
    },
    inactive: false,
    projectLink: 'https://hector.network/',
    twitter: 'https://twitter.com/Hector_Network',
    initTime: { [ChainId.BSC]: 1660649658 },
    initPrice: { [ChainId.BSC]: 18.15486755163556 },
    soldOut: true,
  },
  {
    index: 18,
    contractAddress: {
      [ChainId.BSC]: '0x80ef5c5215ff56a12b5e59c2acfc279c763ae34b',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.floki,
    quoteToken: tokens.wbnb,
    lpToken: tokens.flokiBnb,
    earnToken: tokens.floki,
    billNnftAddress: {
      [ChainId.BSC]: '0x7fd203888661d1f0ae625ed599909f8f97698670',
    },
    inactive: false,
    projectLink: 'https://www.floki.com/',
    twitter: 'https://twitter.com/realflokiinu',
    initTime: { [ChainId.BSC]: 1660217658 },
    initPrice: { [ChainId.BSC]: 0.00001504435067868268 },
    soldOut: true,
  },
  {
    index: 12,
    contractAddress: {
      [ChainId.BSC]: '0x3f02ce456b9da36352439fbd1897c2233a77e46f',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.radar,
    quoteToken: tokens.wbnb,
    lpToken: tokens.radarBnb,
    earnToken: tokens.radar,
    billNnftAddress: {
      [ChainId.BSC]: '0xb0278e43DbD744327FE0d5D0ABa4A77CBFC7fad8',
    },
    inactive: false,
    projectLink: 'https://dappradar.com/',
    twitter: 'https://twitter.com/DappRadar/',
    soldOut: true,
  },
  {
    index: 5,
    contractAddress: {
      [ChainId.BSC]: '0x1c36913ca7c64365a54694cd91dde9c7cf5e44ec',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.ceek,
    quoteToken: tokens.wbnb,
    lpToken: tokens.ceekBnb,
    earnToken: tokens.ceek,
    billNnftAddress: {
      [ChainId.BSC]: '0xb0278e43DbD744327FE0d5D0ABa4A77CBFC7fad8',
    },
    inactive: false,
    projectLink: 'https://ceek.com/',
    twitter: 'https://twitter.com/CEEK/',
    initTime: { [ChainId.BSC]: 1655292858 },
    initPrice: { [ChainId.BSC]: 0.12499413563790174 },
    soldOut: true,
  },
  {
    index: 6,
    contractAddress: {
      [ChainId.BSC]: '0xd9e18fae215f10f4ce35a24fcbfe944fbfb96643',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.nfty,
    quoteToken: tokens.wbnb,
    lpToken: tokens.nftyBnb,
    earnToken: tokens.nfty,
    billNnftAddress: {
      [ChainId.BSC]: '0xb0278e43DbD744327FE0d5D0ABa4A77CBFC7fad8',
    },
    inactive: false,
    projectLink: 'https://nftynetwork.io/',
    twitter: 'https://twitter.com/NFTYLab',
    soldOut: true,
  },
  {
    index: 7,
    contractAddress: {
      [ChainId.BSC]: '0xda0149da023cb204b3ffba33a88d9dd19393c6fc',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.tlos,
    quoteToken: tokens.busd,
    lpToken: tokens.tlosBusd,
    earnToken: tokens.tlos,
    billNnftAddress: {
      [ChainId.BSC]: '0xb0278e43DbD744327FE0d5D0ABa4A77CBFC7fad8',
    },
    inactive: false,
    projectLink: 'https://www.telos.net/',
    twitter: 'https://twitter.com/HelloTelos',
    soldOut: true,
  },
  {
    index: 8,
    contractAddress: {
      [ChainId.BSC]: '0xaf3016100f67c66af15c973cb8da94962653a726',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.stars,
    quoteToken: tokens.wbnb,
    lpToken: tokens.starsBnb,
    earnToken: tokens.stars,
    billNnftAddress: {
      [ChainId.BSC]: '0xb0278e43DbD744327FE0d5D0ABa4A77CBFC7fad8',
    },
    inactive: false,
    projectLink: 'https://www.mogulproductions.com/',
    twitter: 'https://twitter.com/mogulofficial_',
    initTime: { [ChainId.BSC]: 1655292858 },
    initPrice: { [ChainId.BSC]: 0.012596738432285355 },
    soldOut: true,
  },
  {
    index: 9,
    contractAddress: {
      [ChainId.BSC]: '0x6a5be77e3fc1c99ef4aae2846432d61fa78a564c',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.hotcross,
    quoteToken: tokens.busd,
    lpToken: tokens.hotcrossBusd,
    earnToken: tokens.hotcross,
    billNnftAddress: {
      [ChainId.BSC]: '0xb0278e43DbD744327FE0d5D0ABa4A77CBFC7fad8',
    },
    inactive: false,
    projectLink: 'https://hotcross.com/',
    twitter: 'https://twitter.com/hotcrosscom',
    initTime: { [ChainId.BSC]: 1655292858 },
    initPrice: { [ChainId.BSC]: 0.0410817844906574 },
    soldOut: true,
  },
  {
    index: 10,
    contractAddress: {
      [ChainId.BSC]: '0xb5fd0ac23267da501fc6c59c6e12ae32831e72bb',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.hotcross,
    quoteToken: tokens.busd,
    lpToken: tokens.hotcrossBusd,
    earnToken: tokens.hotcross,
    billNnftAddress: {
      [ChainId.BSC]: '0xb0278e43DbD744327FE0d5D0ABa4A77CBFC7fad8',
    },
    inactive: false,
    projectLink: 'https://hotcross.com/',
    twitter: 'https://twitter.com/hotcrosscom',
    initTime: { [ChainId.BSC]: 1655292858 },
    initPrice: { [ChainId.BSC]: 0.0410817844906574 },
    soldOut: true,
  },
  {
    index: 11,
    contractAddress: {
      [ChainId.BSC]: '0x4a3adf34b1f8830fb8d673477d52fa5cb91a2531',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.tlos,
    quoteToken: tokens.busd,
    lpToken: tokens.tlosBusd,
    earnToken: tokens.tlos,
    billNnftAddress: {
      [ChainId.BSC]: '0xb0278e43DbD744327FE0d5D0ABa4A77CBFC7fad8',
    },
    inactive: false,
    projectLink: 'https://www.telos.net/',
    twitter: 'https://twitter.com/HelloTelos',
    initTime: { [ChainId.BSC]: 1655552058 },
    initPrice: { [ChainId.BSC]: 0.1736239460283216 },
    soldOut: true,
  },
  // Old liquiditys
  {
    index: 0,
    contractAddress: {
      [ChainId.BSC]: '0x8b57Fc5BE65118188D50d42fcD5614e447F7FAbE',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.banana,
    quoteToken: tokens.wbnb,
    lpToken: tokens.bananaBnb,
    earnToken: tokens.banana,
    billNnftAddress: {
      [ChainId.BSC]: '0xb0278e43DbD744327FE0d5D0ABa4A77CBFC7fad8',
    },
    inactive: true,
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    soldOut: true,
  },
  {
    index: 1,
    contractAddress: {
      [ChainId.BSC]: '0x4925AcdE0E885170801A74DEBcC8fbA91F3aE29b',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.busd,
    quoteToken: tokens.wbnb,
    lpToken: tokens.bnbBusd,
    earnToken: tokens.banana,
    billNnftAddress: {
      [ChainId.BSC]: '0xb0278e43DbD744327FE0d5D0ABa4A77CBFC7fad8',
    },
    inactive: true,
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    soldOut: true,
  },
  {
    index: 2,
    contractAddress: {
      [ChainId.BSC]: '0xca1612f66292398a5df0ecadd98bb81dc264349d',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.busd,
    quoteToken: tokens.usdc,
    lpToken: tokens.usdcBusd,
    earnToken: tokens.banana,
    billNnftAddress: {
      [ChainId.BSC]: '0xb0278e43DbD744327FE0d5D0ABa4A77CBFC7fad8',
    },
    inactive: true,
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    soldOut: true,
  },
  {
    index: 3,
    contractAddress: {
      [ChainId.BSC]: '0xb2d516086BFc978950e40D2739c72125415441a8',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.eth,
    quoteToken: tokens.wbnb,
    lpToken: tokens.bnbEth,
    earnToken: tokens.banana,
    billNnftAddress: {
      [ChainId.BSC]: '0xb0278e43DbD744327FE0d5D0ABa4A77CBFC7fad8',
    },
    inactive: true,
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    soldOut: true,
  },
  {
    index: 4,
    contractAddress: {
      [ChainId.BSC]: '0xBD9959320cbbC69b2eF7d07fb3f9870cceaeB44f',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.btc,
    quoteToken: tokens.wbnb,
    lpToken: tokens.bnbBtc,
    earnToken: tokens.banana,
    billNnftAddress: {
      [ChainId.BSC]: '0xb0278e43DbD744327FE0d5D0ABa4A77CBFC7fad8',
    },
    inactive: true,
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    soldOut: true,
  },
  {
    index: 13,
    contractAddress: {
      [ChainId.BSC]: '0xE325439439D692DC858Ba887601999D57d35688F',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.banana,
    quoteToken: tokens.wbnb,
    lpToken: tokens.bananaBnb,
    earnToken: tokens.banana,
    billNnftAddress: {
      [ChainId.BSC]: '0x7FD203888661d1F0AE625eD599909f8F97698670',
    },
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    inactive: true,
    soldOut: true,
  },
  {
    index: 14,
    contractAddress: {
      [ChainId.BSC]: '0xf297F71f4664cF7F1Cd5d90720967998A1163cC3',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.busd,
    quoteToken: tokens.wbnb,
    lpToken: tokens.bnbBusd,
    earnToken: tokens.banana,
    billNnftAddress: {
      [ChainId.BSC]: '0x7FD203888661d1F0AE625eD599909f8F97698670',
    },
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    inactive: true,
    soldOut: true,
  },
  {
    index: 15,
    contractAddress: {
      [ChainId.BSC]: '0x937A30Fd88f48B23DeABf4fD43c42cc9d3f3F9Dd',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.busd,
    quoteToken: tokens.usdc,
    lpToken: tokens.usdcBusd,
    earnToken: tokens.banana,
    billNnftAddress: {
      [ChainId.BSC]: '0x7FD203888661d1F0AE625eD599909f8F97698670',
    },
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    inactive: true,
    soldOut: true,
  },
  {
    index: 16,
    contractAddress: {
      [ChainId.BSC]: '0x2D375a67366eDFB92F520aE428E8E08451D638d2',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.eth,
    quoteToken: tokens.wbnb,
    lpToken: tokens.bnbEth,
    earnToken: tokens.banana,
    billNnftAddress: {
      [ChainId.BSC]: '0x7FD203888661d1F0AE625eD599909f8F97698670',
    },
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    inactive: true,
    soldOut: true,
  },
  {
    index: 17,
    contractAddress: {
      [ChainId.BSC]: '0x44921f493887ab8a8b9db54db335f65c1ef8d51a',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.btc,
    quoteToken: tokens.wbnb,
    lpToken: tokens.bnbBtc,
    earnToken: tokens.banana,
    billNnftAddress: {
      [ChainId.BSC]: '0x7FD203888661d1F0AE625eD599909f8F97698670',
    },
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    inactive: true,
    soldOut: true,
  },
  {
    index: 32,
    contractAddress: {
      [ChainId.BSC]: '0xa45dc4b9743d489e9b91070b27e72094b697632d',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.banana,
    quoteToken: tokens.wbnb,
    lpToken: tokens.bananaBnb,
    earnToken: tokens.banana,
    billNnftAddress: {
      [ChainId.BSC]: '0x7FD203888661d1F0AE625eD599909f8F97698670',
    },
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    initTime: { [ChainId.BSC]: 1668467108 },
    initPrice: { [ChainId.BSC]: 0.0709352845097292 },
    inactive: true,
    soldOut: true,
  },
  {
    index: 33,
    contractAddress: {
      [ChainId.BSC]: '0x3a4de56b96d5befd0c9f3fb214372699f2d58947',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.busd,
    quoteToken: tokens.wbnb,
    lpToken: tokens.bnbBusd,
    earnToken: tokens.banana,
    billNnftAddress: {
      [ChainId.BSC]: '0x7FD203888661d1F0AE625eD599909f8F97698670',
    },
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    initTime: { [ChainId.BSC]: 1668467108 },
    initPrice: { [ChainId.BSC]: 0.0709352845097292 },
    inactive: true,
    soldOut: true,
  },
  {
    index: 34,
    contractAddress: {
      [ChainId.BSC]: '0xb0992d86e7d4de0200c463e6451cce1f2775903b',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.busd,
    quoteToken: tokens.usdc,
    lpToken: tokens.usdcBusd,
    earnToken: tokens.banana,
    billNnftAddress: {
      [ChainId.BSC]: '0x7FD203888661d1F0AE625eD599909f8F97698670',
    },
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    initTime: { [ChainId.BSC]: 1668467108 },
    initPrice: { [ChainId.BSC]: 0.0709352845097292 },
    inactive: true,
    soldOut: true,
  },
  {
    index: 35,
    contractAddress: {
      [ChainId.BSC]: '0x08dd5a43e5fc889f2100023f8296e14d9fd40064',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.eth,
    quoteToken: tokens.wbnb,
    lpToken: tokens.bnbEth,
    earnToken: tokens.banana,
    billNnftAddress: {
      [ChainId.BSC]: '0x7FD203888661d1F0AE625eD599909f8F97698670',
    },
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    initTime: { [ChainId.BSC]: 1668467108 },
    initPrice: { [ChainId.BSC]: 0.0709352845097292 },
    inactive: true,
    soldOut: true,
  },
  {
    index: 36,
    contractAddress: {
      [ChainId.BSC]: '0x069002be4ec72333a3fa8a5730e8b84680a190a4',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.btc,
    quoteToken: tokens.wbnb,
    lpToken: tokens.bnbBtc,
    earnToken: tokens.banana,
    billNnftAddress: {
      [ChainId.BSC]: '0x7FD203888661d1F0AE625eD599909f8F97698670',
    },
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/ape_swap',
    initTime: { [ChainId.BSC]: 1668467108 },
    initPrice: { [ChainId.BSC]: 0.0709352845097292 },
    inactive: true,
    soldOut: true,
  },
  {
    index: 63,
    contractAddress: {
      [ChainId.BSC]: '0x1bAFb5916b438fBFc029088da6BF96bd9CF4cb09',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.froyo,
    quoteToken: tokens.wbnb,
    lpToken: tokens.froyoBnb,
    earnToken: tokens.froyo,
    billNnftAddress: {
      [ChainId.BSC]: '0xB75BE16984A27d3023e1cF744E2587e9Aa8750c9',
    },
    projectLink: 'https://apeswap.finance/',
    twitter: 'https://twitter.com/realfroyogames',
    audit:
      'https://github.com/froyogames/public-smart-contracts/blob/main/Smart%20contract%20security%20audit%20report%20-%20Froyo.pdf',
    initTime: { [ChainId.BSC]: 1682255105 },
    initPrice: { [ChainId.BSC]: 0.003065 },
    inactive: true,
    soldOut: true,
  },

  /*
    Polygon Bills
  */
  {
    index: 128,
    contractAddress: {
      [ChainId.MATIC]: '0xE504157665Ac78301C793411542446997c33697b',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.mv,
    quoteToken: tokens.usdt,
    lpToken: tokens.mvUsdt_QS,
    earnToken: tokens.mv,
    billNnftAddress: {
      [ChainId.MATIC]: '0x0f59f11AA94e19b796e8a834A89a66F328155181',
    },
    inactive: false,
    projectLink: 'https://genso.game/en/',
    twitter: 'https://twitter.com/genso_meta',
    audit: 'https://skynet.certik.com/projects/gensokishi-online-metaworld',
    initTime: { [ChainId.MATIC]: 1701892800 },
    initPrice: { [ChainId.MATIC]: 0.00254 },
    soldOut: false,
    billArt: {
      collection: BillArtCollection.Quickswap_Collection1,
    },
    bondPartner: 'QuickSwap',
    tags: ['Gaming'],
    fullDescription:
      'Genso is the metaverse incarnation of “Elemental Knights Online,” which won the 2012 Game of the year (Gold) in Taiwan. Utilizing blockchain technology, they aim to create a new economy within the virtual world. Web3, RPG & Metaverse are all combined within the realm of Gensokishi.',
  },
  {
    index: 127,
    contractAddress: {
      [ChainId.MATIC]: '0xB108dc4b5518079348aB4222Ab8359c50a3f3eba',
    },
    billType: 'reserve',
    billVersion: BillVersion.V2,
    token: tokens.wmatic,
    quoteToken: tokens.wmatic,
    lpToken: tokens.wmatic,
    earnToken: tokens.bull,
    billNnftAddress: {
      [ChainId.MATIC]: '0x0f59f11AA94e19b796e8a834A89a66F328155181',
    },
    inactive: false,
    projectLink: 'https://bullieverse.com/',
    twitter: 'https://twitter.com/Bullieverse',
    initTime: { [ChainId.MATIC]: 1701892800 },
    initPrice: { [ChainId.MATIC]: 0.0948073647 },
    soldOut: false,
    billArt: {
      collection: BillArtCollection.Quickswap_Collection1,
    },
    bondPartner: 'QuickSwap',
    fullDescription:
      'Bullieverse is your gateway to a limitless universe of Games and Social Experiences, where NFTs come alive. A Web3 native entertainment brand, Bullieverse also excels at Art & Lore, with rich storytelling, mesmerizing art, and vivid characters. It stands for a global community united by a shared passion for Web3, where users can connect with adventurers, artists, collectors, and enthusiasts worldwide.',
    tags: ['GameFi'],
  },
  {
    index: 126,
    contractAddress: {
      [ChainId.MATIC]: '0xa772329656bcEDa4e312735bbac24d1EF944e793',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.fxa3a,
    quoteToken: tokens.usdc,
    lpToken: tokens.fxa3aUsdc_QS,
    earnToken: tokens.fxa3a,
    billNnftAddress: {
      [ChainId.MATIC]: '0x0f59f11AA94e19b796e8a834A89a66F328155181',
    },
    inactive: false,
    projectLink: 'https://3adao.org/',
    twitter: 'https://twitter.com/3aaaDAO',
    initTime: { [ChainId.MATIC]: 1701892800 },
    initPrice: { [ChainId.MATIC]: 0.347018667988457 },
    soldOut: false,
    billArt: {
      collection: BillArtCollection.Quickswap_Collection1,
    },
    bondPartner: 'QuickSwap',
    fullDescription:
      "3A is a Zero Interest Rate Lending Protocol for crypto and on-chain RWA safeguarded by Enterprise-Grade Security. They use EURO3's risk model, which stands out as one of the strongest in the space, providing unparalleled protection against DeFi's market volatility and liquidation risks. 3A provides Interest-free liquidity in less than 5 clicks. Fully decentralized and overcollateralized, 3A is a no-brainer.",
    tags: ['StableCoin'],
  },
  {
    index: 125,
    contractAddress: {
      [ChainId.MATIC]: '0x67F95211bD71a9D4d39A61d13a151b73347f5208',
    },
    billType: 'reserve',
    billVersion: BillVersion.V2,
    token: tokens.eth,
    quoteToken: tokens.eth,
    lpToken: tokens.eth,
    earnToken: tokens.wefi,
    billNnftAddress: {
      [ChainId.MATIC]: '0x0f59f11AA94e19b796e8a834A89a66F328155181',
    },
    inactive: false,
    projectLink: 'https://wefi.xyz/',
    twitter: 'https://twitter.com/wefi_xyz',
    audit: 'https://github.com/peckshield/publications/blob/master/audit_reports/PeckShield-Audit-Report-Paxo-v1.0.pdf',
    initTime: { [ChainId.MATIC]: 1701892800 },
    initPrice: { [ChainId.MATIC]: 0.0948073647 },
    soldOut: false,
    billArt: {
      collection: BillArtCollection.Quickswap_Collection1,
    },
    bondPartner: 'QuickSwap',
    fullDescription:
      'WeFi is a decentralized money market protocol for lending and borrowing digital assets, allowing users to earn interest on them and invest in a variety of assets with borrowed funds. It also allows them to unleash the power of leveraged investment vaults. Users can supply single sided liquidity to earn risk free yield and supercharge their DeFi strategies.',
    tags: ['Yield Aggregator'],
  },
  {
    index: 124,
    contractAddress: {
      [ChainId.MATIC]: '0xa03f7F8eC6CF8e217dBE506D990F81B3378e6EEB',
    },
    billType: 'reserve',
    billVersion: BillVersion.V2,
    token: tokens.usdt,
    quoteToken: tokens.usdt,
    lpToken: tokens.usdt,
    earnToken: tokens.ixt,
    billNnftAddress: {
      [ChainId.MATIC]: '0x0f59f11AA94e19b796e8a834A89a66F328155181',
    },
    inactive: false,
    projectLink: 'https://planetix.com/',
    twitter: 'https://twitter.com/Planetix0',
    initTime: { [ChainId.MATIC]: 1701892800 },
    initPrice: { [ChainId.MATIC]: 0.347018667988457 },
    soldOut: false,
    billArt: {
      collection: BillArtCollection.PlanetIX_Collection1,
    },
    bondPartner: 'QuickSwap',
    fullDescription:
      'Planet IX is a Web3 play-to-earn platform fueled by their native IX Token. Every asset on Planet IX is an NFT, allowing users to farm, own, and trade them. The main goal is to restore the planet to its former green-blue glory. Join Planet IX and become an Agent of Change now.',
    tags: ['Gaming', 'NFT'],
  },
  {
    index: 123,
    contractAddress: {
      [ChainId.MATIC]: '0xA26f70b1AdDe39F79E1e688196a0a10120FE1991',
    },
    billType: 'reserve',
    billVersion: BillVersion.V2,
    token: tokens.wmatic,
    quoteToken: tokens.wmatic,
    lpToken: tokens.wmatic,
    earnToken: tokens.a51,
    billNnftAddress: {
      [ChainId.MATIC]: '0x0f59f11AA94e19b796e8a834A89a66F328155181',
    },
    inactive: false,
    projectLink: 'https://uniswap.a51.finance/stats',
    twitter: 'https://twitter.com/A51_Fi',
    audit: 'https://hacken.io/audits/unipilot/',
    initTime: { [ChainId.MATIC]: 1705067401 },
    initPrice: { [ChainId.MATIC]: 0.90803612 },
    soldOut: false,
    billArt: {
      collection: BillArtCollection.ApeBond_Collection1,
    },
    fullDescription:
      'A51 Finance is an Autonomous Liquidity Provisioning (ALP) protocol offering customizable tools for creating tailored liquidity strategies empowering LPs with dynamic adjustments based on their preferences. A51 puts control in the hands of liquidity providers, allowing them to dictate asset management and post-liquidity actions.',
    tags: ['DeFi'],
  },
  {
    index: 122,
    contractAddress: {
      [ChainId.MATIC]: '0x1f3b1E69c874D29e6928ebddA91C22454074d8Fa',
    },
    billType: 'reserve',
    billVersion: BillVersion.V2,
    token: tokens.usdt,
    quoteToken: tokens.usdt,
    lpToken: tokens.usdt,
    earnToken: tokens.trc,
    billNnftAddress: {
      [ChainId.MATIC]: '0x0f59f11AA94e19b796e8a834A89a66F328155181',
    },
    inactive: false,
    projectLink: 'https://metatrace.me/',
    twitter: 'https://twitter.com/trace_meta',
    initTime: { [ChainId.MATIC]: 1701892800 },
    initPrice: { [ChainId.MATIC]: 0.0948073647 },
    soldOut: true,
    billArt: {
      collection: BillArtCollection.Quickswap_Collection1,
    },
    bondPartner: 'QuickSwap',
    fullDescription:
      'The MetaTrace application is a game incorporating a variety of game mechanics aimed at combining geolocation, blockchain environments, and classic game strategies.',
    tags: ['Gaming'],
  },
  {
    index: 121,
    contractAddress: {
      [ChainId.MATIC]: '0x33fA58e25b16D6FfEF63bE1CDba38951A7bd3641',
    },
    billType: 'reserve',
    billVersion: BillVersion.V2,
    token: tokens.usdt,
    quoteToken: tokens.usdt,
    lpToken: tokens.usdt,
    earnToken: tokens.ixt,
    billNnftAddress: {
      [ChainId.MATIC]: '0x0f59f11AA94e19b796e8a834A89a66F328155181',
    },
    inactive: false,
    projectLink: 'https://planetix.com/',
    twitter: 'https://twitter.com/Planetix0',
    initTime: { [ChainId.MATIC]: 1701892800 },
    initPrice: { [ChainId.MATIC]: 0.347018667988457 },
    soldOut: true,
    billArt: {
      collection: BillArtCollection.PlanetIX_Collection1,
    },
    bondPartner: 'QuickSwap',
    fullDescription:
      'Planet IX is a Web3 play-to-earn platform fueled by their native IX Token. Every asset on Planet IX is an NFT, allowing users to farm, own, and trade them. The main goal is to restore the planet to its former green-blue glory. Join Planet IX and become an Agent of Change now.',
    tags: ['Gaming', 'NFT'],
  },
  {
    index: 120,
    contractAddress: {
      [ChainId.MATIC]: '0x262b966d81838D123622Da16B7e97193f4395103',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.ixt,
    quoteToken: tokens.usdt,
    lpToken: tokens.ixtUsdt_QS,
    earnToken: tokens.ixt,
    billNnftAddress: {
      [ChainId.MATIC]: '0x0f59f11AA94e19b796e8a834A89a66F328155181',
    },
    inactive: false,
    projectLink: 'https://planetix.com/',
    twitter: 'https://twitter.com/Planetix0',
    initTime: { [ChainId.MATIC]: 1701892800 },
    initPrice: { [ChainId.MATIC]: 0.347018667988457 },
    soldOut: false,
    billArt: {
      collection: BillArtCollection.PlanetIX_Collection1,
    },
    bondPartner: 'QuickSwap',
    fullDescription:
      'Planet IX is a Web3 play-to-earn platform fueled by their native IX Token. Every asset on Planet IX is an NFT, allowing users to farm, own, and trade them. The main goal is to restore the planet to its former green-blue glory. Join Planet IX and become an Agent of Change now.',
    tags: ['Gaming', 'NFT'],
  },
  {
    index: 119,
    contractAddress: {
      [ChainId.MATIC]: '0x972824E0EC4987Af972F2086fAb2547Fbf7B4d27',
    },
    billType: 'reserve',
    billVersion: BillVersion.V2,
    token: tokens.eth,
    quoteToken: tokens.eth,
    lpToken: tokens.eth,
    earnToken: tokens.fbx,
    billNnftAddress: {
      [ChainId.MATIC]: '0x0f59f11AA94e19b796e8a834A89a66F328155181',
    },
    inactive: false,
    projectLink: 'https://firebot.gg/',
    twitter: 'https://twitter.com/FireBotDAO',
    initTime: { [ChainId.MATIC]: 1701892800 },
    initPrice: { [ChainId.MATIC]: 0.00254 },
    soldOut: false,
    billArt: {
      collection: BillArtCollection.Quickswap_Collection1,
    },
    bondPartner: 'QuickSwap',
    fullDescription:
      'FireBot aims to maximize the potential of DeFi strategies, yield-generating protocols, and automated trading algorithms to offer tokenized investment vehicles for its users. They engineer and structure investment strategies available through smart-contracts deployed on the Polygon blockchain. Their objectives range from high yield to principal protection.',
    tags: ['AI'],
  },
  {
    index: 118,
    contractAddress: {
      [ChainId.MATIC]: '0x87da4b12dbEF3817570Ed32283E2FED85FBf1B93',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.orbs,
    quoteToken: tokens.usdc,
    lpToken: tokens.orbsUsdc_QS,
    earnToken: tokens.orbs,
    billNnftAddress: {
      [ChainId.MATIC]: '0x0f59f11AA94e19b796e8a834A89a66F328155181',
    },
    inactive: false,
    projectLink: 'https://www.orbs.com/overview/',
    twitter: 'https://twitter.com/orbs_network',
    audit: 'https://github.com/orbs-network/orbs-token/blob/master/SmartDec%20OrbsToken%20Security%20Audit.pdf',
    initTime: { [ChainId.MATIC]: 1701892800 },
    initPrice: { [ChainId.MATIC]: 0.00254 },
    soldOut: false,
    billArt: {
      collection: BillArtCollection.Quickswap_Collection1,
    },
    bondPartner: 'QuickSwap',
    fullDescription:
      'Orbs is an open, decentralized and public blockchain infrastructure executed by a secure network of permissionless validators using Proof-of-Stake (PoS) consensus.',
    tags: ['Infra'],
  },
  {
    index: 117,
    contractAddress: {
      [ChainId.MATIC]: '0xc8BD613c3895F5A9dD3e2188706790da676DA002',
    },
    billType: 'reserve',
    billVersion: BillVersion.V2,
    token: tokens.usdt,
    quoteToken: tokens.usdt,
    lpToken: tokens.usdt,
    earnToken: tokens.vext,
    billNnftAddress: {
      [ChainId.MATIC]: '0x0f59f11AA94e19b796e8a834A89a66F328155181',
    },
    inactive: false,
    projectLink: 'https://www.veloce.gg/',
    twitter: 'https://twitter.com/vextoken',
    initTime: { [ChainId.MATIC]: 1701892800 },
    initPrice: { [ChainId.MATIC]: 0.00254 },
    soldOut: false,
    billArt: {
      collection: BillArtCollection.Quickswap_Collection1,
    },
    bondPartner: 'QuickSwap',
    fullDescription:
      'Veloce is an organization operating in gaming and racing. Their gaming outfit runs multiple pro-esports teams while establishing the largest racing media network—their racing teams compete in the Extreme E series as well as the W Series. At Veloce, they are innovators in gaming, racing, and technology.',
    tags: ['Gaming'],
  },
  {
    index: 116,
    contractAddress: {
      [ChainId.MATIC]: '0x7a9700023be5Bd12f35948A4442cc14Bf392Ef62',
    },
    billType: 'reserve',
    billVersion: BillVersion.V2,
    token: tokens.usdt,
    quoteToken: tokens.usdt,
    lpToken: tokens.usdt,
    earnToken: tokens.duckies,
    billNnftAddress: {
      [ChainId.MATIC]: '0x0f59f11AA94e19b796e8a834A89a66F328155181',
    },
    inactive: false,
    projectLink: 'https://www.yellow.org/duckies',
    twitter: 'https://twitter.com/Yellow',
    initTime: { [ChainId.MATIC]: 1701892800 },
    initPrice: { [ChainId.MATIC]: 0.00254 },
    soldOut: false,
    billArt: {
      collection: BillArtCollection.Quickswap_Collection1,
    },
    bondPartner: 'QuickSwap',
    fullDescription:
      'A scalable testing platform built to explore the power of Yellow Network and contribute to the advancement of Web3 technologies that uses real-world assets at a smaller scale. Their Yellow Clearing Network is powered by state channel technology to enable real-time cross-chain trading without the need for brokers to bridge assets.',
    tags: ['Infra'],
  },
  {
    index: 115,
    contractAddress: {
      [ChainId.MATIC]: '0xFA1d99e3CF2816002ea1E29B6eDF0eDbb82f6d22',
    },
    billType: 'reserve',
    billVersion: BillVersion.V2,
    token: tokens.eth,
    quoteToken: tokens.eth,
    lpToken: tokens.eth,
    earnToken: tokens.cone,
    billNnftAddress: {
      [ChainId.MATIC]: '0x0f59f11AA94e19b796e8a834A89a66F328155181',
    },
    inactive: false,
    projectLink: 'https://bitcone.lol/',
    twitter: 'https://twitter.com/BitCone_CONE',
    initTime: { [ChainId.MATIC]: 1701892800 },
    initPrice: { [ChainId.MATIC]: 0.00254 },
    soldOut: false,
    billArt: {
      collection: BillArtCollection.Quickswap_Collection1,
    },
    bondPartner: 'QuickSwap',
    fullDescription:
      'BitCone (CONE) is a Decentralized Community Utility Token made by Cones, for Cones. 608 Billion tokens were created on the Polygon Network (with no mint function) to parallel the 608 Cone Head - Reddit Collectible Avatar NFT, that inspired a meme Community.',
    tags: ['Meme', 'NFT'],
  },
  {
    index: 114,
    contractAddress: {
      [ChainId.MATIC]: '0x6Df8830c1dA2a5bB0e4A98DD84f079E83eE9e9a5',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.knight,
    quoteToken: tokens.eth,
    lpToken: tokens.knightEth_QS,
    earnToken: tokens.knight,
    billNnftAddress: {
      [ChainId.MATIC]: '0x0f59f11AA94e19b796e8a834A89a66F328155181',
    },
    inactive: false,
    projectLink: 'https://www.forestknight.io/',
    twitter: 'https://twitter.com/ForestKnight_io',
    initTime: { [ChainId.MATIC]: 1701892800 },
    initPrice: { [ChainId.MATIC]: 0.00254 },
    soldOut: false,
    billArt: {
      collection: BillArtCollection.Quickswap_Collection1,
    },
    bondPartner: 'QuickSwap',
    fullDescription:
      'Forest Knight is a turn-based strategy game, where players find themselves in an unknown land and need to build their team of heroes and fight against evil. Inspired by successful games, such as Heroes might and magic and clash Royal, the game combines the best of two worlds – a fun and exciting PVE RPG experience, a PVP competitive and social gameplay modes and activities as well as a decentralized item ecosystem through game items as NFTs and world-building powered with DEFI elements.',
    tags: ['Gaming'],
  },
  {
    index: 113,
    contractAddress: {
      [ChainId.MATIC]: '0x3b8E9B6047B9ea84Ea7E9758347F2fF15021c632',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.bom,
    quoteToken: tokens.usdc,
    lpToken: tokens.bomUsdc_QS,
    earnToken: tokens.bom,
    billNnftAddress: {
      [ChainId.MATIC]: '0x0f59f11AA94e19b796e8a834A89a66F328155181',
    },
    inactive: false,
    projectLink: 'https://www.borderless.money/',
    twitter: 'https://twitter.com/Borderless_ReFi',
    audit: 'https://www.borderless.money/bom_secaudit_blockapex.pdf',
    initTime: { [ChainId.MATIC]: 1701892800 },
    initPrice: { [ChainId.MATIC]: 0.00254 },
    soldOut: false,
    billArt: {
      collection: BillArtCollection.Quickswap_Collection1,
    },
    bondPartner: 'QuickSwap',
    fullDescription:
      'Borderless Money is a decentralized finance protocol engineered to redefine how Social Investments are made, using yield-generating products and simultaneously contributing to social causes. An open and borderless digital society, where goods, services, technology, information, opportunities, and capital can flow seamlessly from one hand to many, in a fair and transparent manner.',
    tags: ['DeFi'],
  },
  {
    index: 112,
    contractAddress: {
      [ChainId.MATIC]: '0xc06d6a4Ecb6430417A27d904a905Ab7261c7A815',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.blank,
    quoteToken: tokens.usdc,
    lpToken: tokens.blankUsdc_QS,
    earnToken: tokens.blank,
    billNnftAddress: {
      [ChainId.MATIC]: '0x0f59f11AA94e19b796e8a834A89a66F328155181',
    },
    inactive: false,
    projectLink: 'https://blockwallet.io/',
    twitter: 'https://twitter.com/GetBlockWallet',
    initTime: { [ChainId.MATIC]: 1701892800 },
    initPrice: { [ChainId.MATIC]: 0.00254 },
    soldOut: false,
    billArt: {
      collection: BillArtCollection.Quickswap_Collection1,
    },
    bondPartner: 'QuickSwap',
    fullDescription:
      'BlockWallet is a Web3 wallet focusing on privacy, security, and Self Custody. High Performance, Identity Protection, and Pure Reliability, BlockWallet are just a few of the amazing qualities to describe this Self-Custodial Web3 Crypto Wallet. Swap, Bridge, Buy Crypto, and more with BlockWallet.',
    tags: ['DeFi'],
  },
  {
    index: 111,
    contractAddress: {
      [ChainId.MATIC]: '0x257A720718Cba6356f3aDD881F23Cefc9C57AAeA',
    },
    billType: 'reserve',
    billVersion: BillVersion.V2,
    token: tokens.dai,
    quoteToken: tokens.dai,
    lpToken: tokens.dai,
    earnToken: tokens.elon,
    billNnftAddress: {
      [ChainId.MATIC]: '0x0f59f11AA94e19b796e8a834A89a66F328155181',
    },
    inactive: false,
    projectLink: 'https://dogelonmars.com/',
    twitter: 'https://twitter.com/dogelonmars',
    initTime: { [ChainId.MATIC]: 1701892800 },
    initPrice: { [ChainId.MATIC]: 0.00254 },
    soldOut: true,
    billArt: {
      collection: BillArtCollection.Quickswap_Collection1,
    },
    bondPartner: 'QuickSwap',
  },
  {
    index: 110,
    contractAddress: {
      [ChainId.MATIC]: '0x53859764AC3A49B8e442666d384b57A29fa5d927',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.mv,
    quoteToken: tokens.usdt,
    lpToken: tokens.mvUsdt_QS,
    earnToken: tokens.mv,
    billNnftAddress: {
      [ChainId.MATIC]: '0x0f59f11AA94e19b796e8a834A89a66F328155181',
    },
    inactive: false,
    projectLink: 'https://genso.game/en/',
    twitter: 'https://twitter.com/genso_meta',
    audit: 'https://skynet.certik.com/projects/gensokishi-online-metaworld',
    initTime: { [ChainId.MATIC]: 1701892800 },
    initPrice: { [ChainId.MATIC]: 0.00254 },
    soldOut: true,
    billArt: {
      collection: BillArtCollection.Quickswap_Collection1,
    },
    bondPartner: 'QuickSwap',
    tags: ['Gaming'],
    fullDescription:
      'Genso is the metaverse incarnation of “Elemental Knights Online,” which won the 2012 Game of the year (Gold) in Taiwan. Utilizing blockchain technology, they aim to create a new economy within the virtual world. Web3, RPG & Metaverse are all combined within the realm of Gensokishi.',
  },
  {
    index: 109,
    contractAddress: {
      [ChainId.MATIC]: '0x4F256deDd156fB1Aa6e485E92FeCeB7bc15EBFcA',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.wombat,
    quoteToken: tokens.usdc,
    lpToken: tokens.wombatUsdc,
    earnToken: tokens.wombat,
    billNnftAddress: {
      [ChainId.MATIC]: '0x8860ABc05FefEF93306c8d65a5eFD421F155d67E',
    },
    inactive: false,
    projectLink: 'https://www.wombat.app/',
    twitter: 'https://twitter.com/adoptwombat',
    audit: 'https://hacken.io/wp-content/uploads/2022/07/Wombat_20072022_SCAudit_Report_2.pdf',
    initTime: { [ChainId.MATIC]: 1684274400 },
    initPrice: { [ChainId.MATIC]: 0.00254 },
    soldOut: true,
  },
  {
    index: 108,
    contractAddress: {
      [ChainId.MATIC]: '0x6c83cF7e4305f6D66930754f4855f2ea4357BaBf',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.cgg,
    quoteToken: tokens.usdc,
    lpToken: tokens.cggUsdc,
    earnToken: tokens.usdc,
    billNnftAddress: {
      [ChainId.MATIC]: '0x8860ABc05FefEF93306c8d65a5eFD421F155d67E',
    },
    inactive: false,
    projectLink: 'https://chainguardians.io/',
    twitter: 'https://twitter.com/chain_guardians',
    audit: 'https://www.certik.com/projects/chainguardians?utm_source=CMC&utm_campaign=AuditByCertiKLink',
    initTime: { [ChainId.MATIC]: 1682590408 },
    initPrice: { [ChainId.MATIC]: 0.085485 },
    soldOut: true,
    showcaseToken: tokens.cgg,
  },
  {
    index: 107,
    contractAddress: {
      [ChainId.MATIC]: '0x5F05a78734853C34661D1C6Db46b5661dEef7374',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.kom,
    quoteToken: tokens.wmatic,
    lpToken: tokens.komMatic,
    earnToken: tokens.kom,
    billNnftAddress: {
      [ChainId.MATIC]: '0x8860ABc05FefEF93306c8d65a5eFD421F155d67E',
    },
    inactive: false,
    projectLink: 'https://www.kommunitas.net/',
    twitter: 'https://twitter.com/Kommunitas1',
    audit: 'https://docs.kommunitas.net/audit/shellboxes/security-audit-by-shellboxes',
    initTime: { [ChainId.MATIC]: 1682469864 },
    initPrice: { [ChainId.MATIC]: 0.0013484 },
    soldOut: true,
  },
  {
    index: 106,
    contractAddress: {
      [ChainId.MATIC]: '0x220A6561490eE6926F4dBE8416E5cb0C2277a13D',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.dogira,
    quoteToken: tokens.wmatic,
    lpToken: tokens.dogiraMatic,
    earnToken: tokens.dogira,
    billNnftAddress: {
      [ChainId.MATIC]: '0x8860ABc05FefEF93306c8d65a5eFD421F155d67E',
    },
    inactive: false,
    projectLink: 'https://www.dogira.net/',
    twitter: 'https://twitter.com/DogiraOfficial',
    audit: 'https://www.certik.com/address/0x47c5aa82fda7a79c7965beb6d7c6a265fe59921b',
    initTime: { [ChainId.MATIC]: 1679498540 },
    initPrice: { [ChainId.MATIC]: 0.000939 },
    soldOut: true,
  },
  {
    index: 105,
    contractAddress: {
      [ChainId.MATIC]: '0xB226290a0078a18b0A2a3BDA7460b82b966B656a',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.leap,
    quoteToken: tokens.wmatic,
    lpToken: tokens.leapMatic,
    earnToken: tokens.leap,
    billNnftAddress: {
      [ChainId.MATIC]: '0x8860ABc05FefEF93306c8d65a5eFD421F155d67E',
    },
    inactive: false,
    projectLink: 'https://www.leaptoken.io/',
    twitter: 'https://twitter.com/Play_Leap',
    audit: 'https://github.com/HashEx/public_audits/blob/master/PlayLeap/PlayLeap.pdf',
    initTime: { [ChainId.MATIC]: 1679389058 },
    initPrice: { [ChainId.MATIC]: 0.021342 },
    soldOut: true,
  },
  {
    index: 104,
    contractAddress: {
      [ChainId.MATIC]: '0x66466E907B63D5c7a7Dd560e490adB596DFDf61B',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.usdc,
    quoteToken: tokens.cgg,
    lpToken: tokens.cggUsdc,
    earnToken: tokens.usdc,
    billNnftAddress: {
      [ChainId.MATIC]: '0x8860ABc05FefEF93306c8d65a5eFD421F155d67E',
    },
    inactive: false,
    projectLink: 'https://chainguardians.io/',
    twitter: 'https://twitter.com/chain_guardians',
    audit: 'https://www.certik.com/projects/chainguardians?utm_source=CMC&utm_campaign=AuditByCertiKLink',
    initTime: { [ChainId.MATIC]: 1676626187 },
    initPrice: { [ChainId.MATIC]: 0.077899 },
    soldOut: true,
    showcaseToken: tokens.cgg,
  },
  {
    index: 103,
    contractAddress: {
      [ChainId.MATIC]: '0xb53b2706897a26fec9113f3e2371ac5b2b88970a',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.axn,
    quoteToken: tokens.usdc,
    lpToken: tokens.axnUsdc,
    earnToken: tokens.axn,
    billNnftAddress: {
      [ChainId.MATIC]: '0xa35c3fcd306cf69fed19e8c7b15c8b3904ea609d',
    },
    inactive: false,
    projectLink: 'https://axion.network/',
    twitter: 'https://twitter.com/axion_network',
    initTime: { [ChainId.MATIC]: 1669973048 },
    initPrice: { [ChainId.MATIC]: 0.000015665249761458396 },
    soldOut: true,
  },
  {
    index: 100,
    contractAddress: {
      [ChainId.MATIC]: '0x643a6848a3906182a94ec857a41c1735664c677f',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.dogira,
    quoteToken: tokens.wmatic,
    lpToken: tokens.dogiraMatic,
    earnToken: tokens.dogira,
    billNnftAddress: {
      [ChainId.MATIC]: '0xa35c3fcd306cf69fed19e8c7b15c8b3904ea609d',
    },
    inactive: false,
    projectLink: 'https://www.dogira.net/',
    twitter: 'https://twitter.com/DogiraOfficial',
    soldOut: true,
  },
  {
    index: 101,
    contractAddress: {
      [ChainId.MATIC]: '0xca48704b19a053ebb5a077c215dcb4be745c9fe5',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.traxx,
    quoteToken: tokens.wmatic,
    lpToken: tokens.traxxMatic,
    earnToken: tokens.traxx,
    billNnftAddress: {
      [ChainId.MATIC]: '0xa35c3fcd306cf69fed19e8c7b15c8b3904ea609d',
    },
    inactive: false,
    projectLink: 'https://www.tokentraxx.com/',
    twitter: 'https://twitter.com/TokenTraxx',
    initTime: { [ChainId.MATIC]: 1663846458 },
    initPrice: { [ChainId.MATIC]: 0.18650580540316708 },
    soldOut: true,
  },
  {
    index: 102,
    contractAddress: {
      [ChainId.MATIC]: '0xc16cf0e4c090409765757e5b13afdfc500148678',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.dogira,
    quoteToken: tokens.wmatic,
    lpToken: tokens.dogiraMatic,
    earnToken: tokens.dogira,
    billNnftAddress: {
      [ChainId.MATIC]: '0xa35c3fcd306cf69fed19e8c7b15c8b3904ea609d',
    },
    inactive: false,
    projectLink: 'https://www.dogira.net/',
    twitter: 'https://twitter.com/DogiraOfficial',
    initTime: { [ChainId.MATIC]: 1664969658 },
    initPrice: { [ChainId.MATIC]: 0.0015076537321486155 },
    soldOut: true,
  },

  /*
    Telos Bills
  */
  {
    index: 211,
    contractAddress: {
      [ChainId.TLOS]: '0x84662B6bBef1E81aBcAa8372678ADBbA49d07C2c',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.banana,
    quoteToken: tokens.tlos,
    lpToken: tokens.bananaTlos,
    earnToken: tokens.tlos,
    billNnftAddress: {
      [ChainId.TLOS]: '0x29DD194bDc735A87637f2736A90AA585B74F5FD4',
    },
    inactive: false,
    projectLink: 'https://www.telos.net/',
    twitter: 'https://twitter.com/HelloTelos',
    initTime: { [ChainId.TLOS]: 1676929798 },
    initPrice: { [ChainId.TLOS]: 0.2206 },
    soldOut: true,
  },
  {
    index: 212,
    contractAddress: {
      [ChainId.TLOS]: '0x5d72Be8B3E8F07f9D41944a148a109F18Cb65f55',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.eth,
    quoteToken: tokens.tlos,
    lpToken: tokens.ethTlos,
    earnToken: tokens.tlos,
    billNnftAddress: {
      [ChainId.TLOS]: '0x29DD194bDc735A87637f2736A90AA585B74F5FD4',
    },
    inactive: false,
    projectLink: 'https://www.telos.net/',
    twitter: 'https://twitter.com/HelloTelos',
    initTime: { [ChainId.TLOS]: 1676929798 },
    initPrice: { [ChainId.TLOS]: 0.2206 },
    soldOut: true,
  },
  {
    index: 213,
    contractAddress: {
      [ChainId.TLOS]: '0xF5F350ddcC3DE0733B62E71844A9C8BE4032DE52',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.btc,
    quoteToken: tokens.tlos,
    lpToken: tokens.btcTlos,
    earnToken: tokens.tlos,
    billNnftAddress: {
      [ChainId.TLOS]: '0x29DD194bDc735A87637f2736A90AA585B74F5FD4',
    },
    inactive: false,
    projectLink: 'https://www.telos.net/',
    twitter: 'https://twitter.com/HelloTelos',
    initTime: { [ChainId.TLOS]: 1676929798 },
    initPrice: { [ChainId.TLOS]: 0.2206 },
    soldOut: true,
  },
  {
    index: 214,
    contractAddress: {
      [ChainId.TLOS]: '0xE5325b9F69255098786908c79A234584FfC62dcb',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.usdt,
    quoteToken: tokens.tlos,
    lpToken: tokens.usdtTlos,
    earnToken: tokens.tlos,
    billNnftAddress: {
      [ChainId.TLOS]: '0x29DD194bDc735A87637f2736A90AA585B74F5FD4',
    },
    inactive: false,
    projectLink: 'https://www.telos.net/',
    twitter: 'https://twitter.com/HelloTelos',
    initTime: { [ChainId.TLOS]: 1676929798 },
    initPrice: { [ChainId.TLOS]: 0.2206 },
    soldOut: true,
  },
  {
    index: 215,
    contractAddress: {
      [ChainId.TLOS]: '0x07273ae6E441491fc4410aE9F8371AEdd7A19499',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.usdc,
    quoteToken: tokens.tlos,
    lpToken: tokens.usdcTlos,
    earnToken: tokens.tlos,
    billNnftAddress: {
      [ChainId.TLOS]: '0x29DD194bDc735A87637f2736A90AA585B74F5FD4',
    },
    inactive: true,
    projectLink: 'https://www.telos.net/',
    twitter: 'https://twitter.com/HelloTelos',
    initTime: { [ChainId.TLOS]: 1676929798 },
    initPrice: { [ChainId.TLOS]: 0.2206 },
    soldOut: true,
  },
  {
    index: 206,
    contractAddress: {
      [ChainId.TLOS]: '0x1Bb2Eb63c2B2CD1cE5887B4628694276E637BeD7',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.banana,
    quoteToken: tokens.tlos,
    lpToken: tokens.bananaTlos,
    earnToken: tokens.tlos,
    billNnftAddress: {
      [ChainId.TLOS]: '0x9084C442286E23617694101d5575bEA48C2cf621',
    },
    inactive: true,
    projectLink: 'https://www.telos.net/',
    twitter: 'https://twitter.com/HelloTelos',
    initTime: { [ChainId.TLOS]: 1668849848 },
    initPrice: { [ChainId.TLOS]: 0.1743935819281813 },
    soldOut: true,
  },
  {
    index: 207,
    contractAddress: {
      [ChainId.TLOS]: '0xe3725Eccd93bb544b360BB572893eF17E42219c4',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.eth,
    quoteToken: tokens.tlos,
    lpToken: tokens.ethTlos,
    earnToken: tokens.tlos,
    billNnftAddress: {
      [ChainId.TLOS]: '0x9084C442286E23617694101d5575bEA48C2cf621',
    },
    inactive: true,
    projectLink: 'https://www.telos.net/',
    twitter: 'https://twitter.com/HelloTelos',
    initTime: { [ChainId.TLOS]: 1668849848 },
    initPrice: { [ChainId.TLOS]: 0.1743935819281813 },
    soldOut: true,
  },
  {
    index: 208,
    contractAddress: {
      [ChainId.TLOS]: '0x81f2BA390934df655eABcc3787F4f5f694e722ED',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.btc,
    quoteToken: tokens.tlos,
    lpToken: tokens.btcTlos,
    earnToken: tokens.tlos,
    billNnftAddress: {
      [ChainId.TLOS]: '0x9084C442286E23617694101d5575bEA48C2cf621',
    },
    inactive: true,
    projectLink: 'https://www.telos.net/',
    twitter: 'https://twitter.com/HelloTelos',
    initTime: { [ChainId.TLOS]: 1668849848 },
    initPrice: { [ChainId.TLOS]: 0.1743935819281813 },
    soldOut: true,
  },
  {
    index: 209,
    contractAddress: {
      [ChainId.TLOS]: '0x68aEaAbE6EbBd2b5Cf0130d0BAAef1Ec5E0539Aa',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.usdt,
    quoteToken: tokens.tlos,
    lpToken: tokens.usdtTlos,
    earnToken: tokens.tlos,
    billNnftAddress: {
      [ChainId.TLOS]: '0x9084C442286E23617694101d5575bEA48C2cf621',
    },
    inactive: true,
    projectLink: 'https://www.telos.net/',
    twitter: 'https://twitter.com/HelloTelos',
    initTime: { [ChainId.TLOS]: 1668849848 },
    initPrice: { [ChainId.TLOS]: 0.1743935819281813 },
    soldOut: true,
  },
  {
    index: 210,
    contractAddress: {
      [ChainId.TLOS]: '0xb3a0b38E5C07db5238538a640155562085F22bCe',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.usdc,
    quoteToken: tokens.tlos,
    lpToken: tokens.usdcTlos,
    earnToken: tokens.tlos,
    billNnftAddress: {
      [ChainId.TLOS]: '0x9084C442286E23617694101d5575bEA48C2cf621',
    },
    inactive: true,
    projectLink: 'https://www.telos.net/',
    twitter: 'https://twitter.com/HelloTelos',
    initTime: { [ChainId.TLOS]: 1668849848 },
    initPrice: { [ChainId.TLOS]: 0.1743935819281813 },
    soldOut: true,
  },
  {
    index: 201,
    contractAddress: {
      [ChainId.TLOS]: '0x7C02E197BC2644FE0E9834641F80aC0A15055ad7',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.banana,
    quoteToken: tokens.tlos,
    lpToken: tokens.bananaTlos,
    earnToken: tokens.tlos,
    billNnftAddress: {
      [ChainId.TLOS]: '0x9084C442286E23617694101d5575bEA48C2cf621',
    },
    inactive: true,
    projectLink: 'https://www.telos.net/',
    twitter: 'https://twitter.com/HelloTelos',
    soldOut: true,
  },
  {
    index: 202,
    contractAddress: {
      [ChainId.TLOS]: '0x76a33a5d002C711A3F353cB4Ee2B112B579c317a',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.eth,
    quoteToken: tokens.tlos,
    lpToken: tokens.ethTlos,
    earnToken: tokens.tlos,
    billNnftAddress: {
      [ChainId.TLOS]: '0x9084C442286E23617694101d5575bEA48C2cf621',
    },
    inactive: true,
    projectLink: 'https://www.telos.net/',
    twitter: 'https://twitter.com/HelloTelos',
    soldOut: true,
  },
  {
    index: 203,
    contractAddress: {
      [ChainId.TLOS]: '0xa08A0125F4B85320e2357174EDC9E262098BF850',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.btc,
    quoteToken: tokens.tlos,
    lpToken: tokens.btcTlos,
    earnToken: tokens.tlos,
    billNnftAddress: {
      [ChainId.TLOS]: '0x9084C442286E23617694101d5575bEA48C2cf621',
    },
    inactive: true,
    projectLink: 'https://www.telos.net/',
    twitter: 'https://twitter.com/HelloTelos',
    soldOut: true,
  },
  {
    index: 204,
    contractAddress: {
      [ChainId.TLOS]: '0xD1FbE4EAFAcB7FdC56F7Aa8dA24198b01942C677',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.usdt,
    quoteToken: tokens.tlos,
    lpToken: tokens.usdtTlos,
    earnToken: tokens.tlos,
    billNnftAddress: {
      [ChainId.TLOS]: '0x9084C442286E23617694101d5575bEA48C2cf621',
    },
    inactive: true,
    projectLink: 'https://www.telos.net/',
    twitter: 'https://twitter.com/HelloTelos',
    soldOut: true,
  },
  {
    index: 205,
    contractAddress: {
      [ChainId.TLOS]: '0xc17a1ea1Da9975A2fad85eC7C39AEBA2B4BfEA11',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V1,
    token: tokens.usdc,
    quoteToken: tokens.tlos,
    lpToken: tokens.usdcTlos,
    earnToken: tokens.tlos,
    billNnftAddress: {
      [ChainId.TLOS]: '0x9084C442286E23617694101d5575bEA48C2cf621',
    },
    inactive: true,
    projectLink: 'https://www.telos.net/',
    twitter: 'https://twitter.com/HelloTelos',
    soldOut: true,
  },
  /*
      Arbitrum Bills - ID started from 2001
  */
  {
    index: 2004,
    contractAddress: {
      [ChainId.ARBITRUM]: '0x04dA96Fb9F43491D3601F2b04424ABE7F12F700d',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.open,
    quoteToken: tokens.eth,
    lpToken: tokens.openEth,
    earnToken: tokens.open,
    billNnftAddress: {
      [ChainId.ARBITRUM]: '0xAC707999D05C4048844B78c737D9512DFDff524d',
    },
    inactive: false,
    projectLink: 'https://openworld.vision/',
    twitter: 'https://twitter.com/OpenWorldFi',
    audit: 'https://wiki.openworld.vision/audit',
    initTime: { [ChainId.ARBITRUM]: 1700149206 },
    initPrice: { [ChainId.ARBITRUM]: 0.003660886786 },
    soldOut: true,
    billArt: {
      collection: BillArtCollection.ApeBond_Collection1,
    },
  },
  {
    index: 2003,
    contractAddress: {
      [ChainId.ARBITRUM]: '0xb5022e9DE06793D74c7892cD0e7D820cdEd05611',
    },
    billType: 'reserve',
    billVersion: BillVersion.V2,
    token: tokens.usdt,
    quoteToken: tokens.usdt,
    lpToken: tokens.usdt,
    earnToken: tokens.cruize,
    billNnftAddress: {
      [ChainId.ARBITRUM]: '0xAC707999D05C4048844B78c737D9512DFDff524d',
    },
    inactive: false,
    projectLink: 'https://www.cruize.finance/',
    twitter: 'https://twitter.com/CruizeFinance',
    audit: 'https://docs.cruize.finance/technical-details/audits',
    initTime: { [ChainId.ARBITRUM]: 1689955200 },
    initPrice: { [ChainId.ARBITRUM]: 0.0202 },
    soldOut: true,
  },
  {
    index: 2002,
    contractAddress: {
      [ChainId.ARBITRUM]: '0xd194D68eF1055741c943958002A19622f5141E86',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.open,
    quoteToken: tokens.eth,
    lpToken: tokens.openEth,
    earnToken: tokens.open,
    billNnftAddress: {
      [ChainId.ARBITRUM]: '0xAC707999D05C4048844B78c737D9512DFDff524d',
    },
    inactive: false,
    projectLink: 'https://openworld.vision/',
    twitter: 'https://twitter.com/OpenWorldVision',
    audit: 'https://wiki.openworld.vision/audit',
    initTime: { [ChainId.ARBITRUM]: 1683237600 },
    initPrice: { [ChainId.ARBITRUM]: 0.02259 },
    soldOut: true,
  },
  {
    index: 2001,
    contractAddress: {
      [ChainId.ARBITRUM]: '0x9Bd72c6c69cC314AdfF396cC2b34fF7e7fbd68ad',
    },
    billType: 'liquidity',
    billVersion: BillVersion.V2,
    token: tokens.onyx,
    quoteToken: tokens.eth,
    lpToken: tokens.onyxEth,
    earnToken: tokens.onyx,
    billNnftAddress: {
      [ChainId.ARBITRUM]: '0xAC707999D05C4048844B78c737D9512DFDff524d',
    },
    inactive: false,
    projectLink: 'https://www.onyxdao.finance/',
    twitter: 'https://twitter.com/OnyxDAO',
    audit: 'https://hashex.org/audits/onyx-dao/',
    initTime: { [ChainId.ARBITRUM]: 1679952396 },
    initPrice: { [ChainId.ARBITRUM]: 1.5528 },
    soldOut: true,
  },
]

export default bills
